<template >
  <div id="signin">
    <div class="row p-sm-3">
      <div class="col-sm-7 ">
        <div class="logo ml-3">
          <img
            src="../assets/img/logo.png"
            alt=""
          >
        </div>
        <div class="auth-form  mx-auto">
          <div class="heading d-flex justify-content-start ">
            <img
              src="../assets/img/key.svg"
              alt=""
            >
            <div class="ml-3">
              <h3> Welcome Back!</h3>
              <p>Sign in to continue</p>
            </div>
          </div>
          <div>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form
                class="mt-4"
                @submit.prevent='handleSubmit(handleSignin)'
              >
                <div class="form-group">
                  <label for="">Enter Your Last Name</label>
                  <validation-provider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="What’s your Last name?"
                      v-model="username"
                    >
                    <span class="extra">Enter your registered surname</span>
                    <br />
                    <span class="err_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <!-- <div class="form-group ">
                    <a
                      class="forgot"
                      @click="$router.push('/forgot-password')"
                      style="cursor:pointer;"
                      href="/forgot-password"
                    >Forgot Password?</a>
                  </div> -->
                <div class="form-group">
                  <label for="">Enter Token</label>
                  <validation-provider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <!-- <input
                      type="password"
                      class="form-control"
                      placeholder="Please enter your password"
                      v-model="password"
                    > -->
                    <div class="input-wrapper">
                      <PincodeInput
                        :length="6"
                        v-model="passcode"
                        placeholder="0"
                      />
                    </div>
                    <span class="err_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div class="form-group">
                  <button
                    type="submit"
                    class="btn"
                  >
                    Sign in
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <div class="col-sm-5 d-none d-sm-block">
        <div class="auth-details px-3">
          <div class="mr-3 title  text-right">
            <img
              src="../assets/img/logo-mini.png"
              alt=""
            >
            <h4>Welcome To <span style="color:green">n</span>tisa POS <br></h4>
          </div>
          <div class="login-bg">
            <img
              src="../assets/img/login-bg.svg"
              alt=""
            >
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import Sync from '@/workers/Sync'
import Utils from '@/utils.js'
export default {
  name: "Login",
  data () {
    return {
      username: '',
      passcode: '',
      role: 'store_attendant'
    }
  },

  methods: {
    getUsers () {
      let req = {
        what: "getUsers",
        useToken: true,
        params: {
          merchID: this.$store.getters.getMerchID,
          role: this.role
        }

      }

      this.$store.dispatch('getUsers', req);
    },
    handleSignin () {
      let req = {
        what: "login",
        showLoader: false,
        params: {
          firstname: this.username,
          token: this.passcode,
          role: this.role
        }
      }

      var loading_html =
        '<div style="height:150px;width:150px;margin: 0 auto;"><img style="width: 100%;" src="https://trails.ca/wp-content/uploads/2020/10/loading-spinner.gif" /></div>';

      this.$swal.fire({
        title: "",
        html: loading_html,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: false
      });

      this.$store.dispatch('login', req)
        // this.$request.makeGetRequest(req)
        .then(async res => {
          // 
          this.getUsers();
          // console.log('in main thread' + this.$store.getters.isLoggedIn)
          // await this.$store.restored
          // if (window.matchMedia('(display-mode: standalone)').matches) {

          // }
          // if (res.type == 'login') {
          // if (res.data.status) {
          // this.$store.dispatch("setUser", res.data.data);
          // this.$store.dispatch("setToken", res.data.token);
          // this.$store.dispatch("setCategory", res.data.data.businesscategory);
          let result = await Utils.checkOnlineStatus()
          if (result) {
            if (this.$store.getters.user.role == 'kitchen') {
              this.$swal.close();
              this.$router.push({ name: "Kitchen" });
            }
            else {
              if (this.$store.state.first) {
                let shouldFetch = await this.$store.dispatch('posts/shouldFetch')
                if (this.$store.state.load == false) {
                  Sync.send({ load: true, data: this.$store.getters.user, token: this.$store.getters.token, run: shouldFetch })
                }
              }
              this.$swal.close();
              this.$router.push({ name: "Online Orders" });
            }
          } else {
            this.$swal.close();
            this.$router.push({ name: "Walk-in" })
          }
          // }
          // else {
          //   this.$swal.fire("Error", res.data.message, "error");
          // }
          // }
        })
        .catch(error => {
          console.log(error)
          this.$swal.fire("Error", error, "error");
        });
    },



  }
}
</script>
<style>
.vue-pincode-input {
  margin-right: 7px !important;
  padding: 8px !important;
  max-width: 40px !important;
  text-align: center;
  font-size: 1.2rem !important;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 3px rgb(0 0 0 / 50%) !important;
}
.vue-pincode-input:focus {
  box-shadow: 0 0 3px rgb(0 0 0 / 50%) !important;
}
</style>