<template>
  <div id="products">
    <Sidemenu />
    <div id="main">
      <Topmenu />

      <div class="filter-container ">
        <div class="row h-100 mx-1">
          <div class="col-xl-5 col-lg-4 my-auto filter-title">
            <div class="d-flex">
              <h5 class="title mr-2">Menu</h5>
              <p class="subtitle pl-2">{{cat_products.length}} items</p>
            </div>
          </div>
          <div class="col-xl-7 col-lg-8 my-auto filter-form">
            <div class="d-flex justify-content-between flex-wrap">
              <div
                id="search"
                class="my-auto"
              >
                <input
                  class="form-control "
                  type="text"
                  v-model="search"
                  placeholder="Search by product name and product SKU"
                >
                <span class="fa fa-search"></span>
              </div>
              <!-- <div class="">
              <input
                class="form-check-input"
                type="checkbox"
                id="gridCheck"
              >
              <label
                class="form-check-label  ml-5 mt-2"
                for="gridCheck"
              >
                Show all discounts
              </label>
            </div> -->
              <!-- <div class="my-auto">
              <p class="pagination"> Showing 1-20 of 48</p>

            </div>
            <div class="my-auto d-inline">
              <span class="fa fa-chevron-left"></span>
              <span class="fa fa-chevron-right ml-4"></span>
            </div> -->
            </div>
          </div>
        </div>
      </div>
      <Menu
        :categories=categories
        v-on:childToParent="activeCategory"
      ></Menu>
      <div class="main-content my-4">
        <div
          v-if="filteredList.length !==0"
          class="row mx-2"
        >
          <div
            class="col-xl-3 col-md-4 col-sm-6"
            v-for="(pro, index) in filteredList"
            v-bind:key="index"
          >
            <div
              class="pro p-sm-4"
              v-bind:class="pro.outofstock ==true? 'stock-out':''"
              data-toggle="modal"
              @click="openModal(pro)"
              data-target="#product-modal"
            >
              <div class="text-center">
                <img
                  v-lazy="pro.photo"
                  alt=""
                  class="img-fluid"
                >
              </div>
              <div class="pro-details mt-3">
                <p class="name">
                  {{pro.productname}}
                </p>
                <div class="d-flex justify-content-between mt-3">
                  <h5 class="price">₦ {{formatPrice(pro.sellingprice)}}</h5>
                  <p
                    v-if="pro.outofstock !== true && pro.stockqty !=='Unlimited'"
                    class="qty my-auto"
                  >{{pro.stockqty}}
                    <span>left</span>
                  </p>
                  <p
                    class="qty my-auto"
                    v-else-if="pro.outofstock !== true && pro.stockqty =='Unlimited'"
                  >Unlimited</p>
                  <p
                    v-else
                    class="qty text-danger my-auto"
                  > Out of Stock</p>
                </div>
              </div>
            </div>
          </div>
          <mugen-scroll
            :handler="fetchProducts"
            :should-handle="shouldLoad"
          >
          </mugen-scroll>

        </div>
        <div
          class="text-center mt-5 "
          v-else
        >
          <img
            class="img-fluid"
            src="../assets/img/no-result.png"
            alt=""
          >
        </div>
      </div>
    </div>

    <!-- product modal -->
    <div
      v-if="Object.keys(product).length !== 0"
      id="product-modal"
      class="modal"
      tabindex="-1"
      data-backdrop='static'
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title mx-4">
              Edit Product
            </h5>

            <img
              class="close mx-3"
              data-dismiss="modal"
              aria-label="Close"
              src="../assets/img/close.png"
              alt=""
            >
          </div>
          <div class="modal-body mx-4">
            <div class="pro-img">
              <img
                v-lazy="product.photo"
                alt=""
                class="img-fluid"
              >
            </div>
            <div class="details w-75">
              <h3 class="name">{{product.productname}}</h3>
              <div
                v-if="product.variance"
                v-for="(row, ix) in product.variance"
                v-bind:key="ix"
              >
                <p class="variance d-flex"><strong>{{Object.keys(row)[0]}} : </strong>
                  <span
                    class="ml-1"
                    v-for="va in Object.values(row)[0]"
                  > {{va.featurename}} <span class="mx-1">|</span> </span>
                </p>

              </div>

              <div class="d-flex m-0">
                <h5 class="price">₦ {{formatPrice(product.sellingprice)}}</h5>
                <span class="mx-2 text-bold">.</span>
                <p
                  v-if="product.outofstock !== true && product.stockqty !=='Unlimited'"
                  class="qty my-auto"
                >{{product.stockqty}}
                  <span>left</span>
                </p>
                <p
                  class="qty my-auto"
                  v-else-if="product.outofstock !== true &&product.stockqty =='Unlimited'"
                >Unlimited</p>
                <p
                  v-else
                  class="qty text-danger my-auto"
                > Out of Stock</p>
              </div>
              <div class="avail mt-4">
                <h5>Product Availability</h5>
                <p>Set the availability of a product for the customers</p>

                <div class="mt-2">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      @change="edittype.push('avail')"
                      id="exampleRadios1"
                      value="0"
                      v-model="availability"
                    >
                    <label
                      class="form-check-label"
                      for="exampleRadios1"
                      v-bind:class="availability=='0'?'checked':''"
                    >
                      Available
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      @change=" edittype.push('outofstock')"
                      class="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios2"
                      value="1"
                      v-model="availability"
                    >
                    <label
                      class="form-check-label"
                      v-bind:class="availability=='1'?'checked':''"
                      for="exampleRadios2"
                    >
                      Out of stock
                    </label>
                  </div>

                </div>
              </div>
              <!-- <div
                id="add-stock"
                class="form-group mt-4"
              >
                <label for="">Stock up Product</label>
                <input
                  type="text"
                  @change=" edittype.push('addstock')"
                  v-model="inwardqty"
                  class="form-control"
                  placeholder="Add more items to your stock"
                >
              </div> -->
            </div>
          </div>
          <div class="modal-footer  border-0">
            <button
              class="cancel-close btn"
              data-dismiss="modal"
            >Cancel</button>
            <button
              v-bind:disabled="product.outofstock? true:false"
              id="share-btn"
              type="button"
              @click.prevent="onShare"
              class="btn  ml-3"
            >Share Product<i class="fas fa-share-alt ml-2"></i></button>
            <button
              type="button"
              @click.prevent="editProduct"
              class="btn  ml-3"
            >Save Changes</button>
          </div>
        </div>
      </div>
    </div>

    <!-- share modal -->
    <div
      v-if="Object.keys(product).length !== 0"
      id="sharemodal"
      class="modal"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title mx-4">
              Shareable link</h5>

            <img
              class="close mx-3"
              data-dismiss="modal"
              aria-label="Close"
              src="../assets/img/close.png"
              alt=""
            >
          </div>
          <div class="modal-body mx-4">
            <p
              class="title"
              style="font-size:13px"
            >Share this product on social networks of your choice</p>
            <div class="form-group mt-4">
              <label for="">Copy link</label>
              <input
                id="product_link"
                :value="product_link"
                type="text"
                class="form-control"
              >
            </div>
            <div class="form-group">
              <button
                @click.prevent="onCopy"
                class="btn "
              >Copy</button>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import * as $ from "jquery";
import Sidemenu from '@/components/sidemenu'
import Topmenu from '@/components/topmenu'
import Menu from '@/components/menu'

import { createNamespacedHelpers } from 'vuex'
// import { mapActions, mapGetters } from 'vuex'
const { mapActions, mapGetters } = createNamespacedHelpers('products')

export default {
  name: 'Products',
  components: {
    Sidemenu, Topmenu, Menu
  },
  data () {
    return {
      search: '',
      page: 0,
      loading: false,
      index: 0,
      length: 0,
      edit: false,
      all_products: [],
      cat_products: [],
      product: {},
      categories: [],
      edittype: [],
      availability: '0',
      inwardqty: '',
      product_link: ''
    }
  },
  created () {
    this.fetchProducts(this.index)
  },
  watch: {
    index (val) {
      this.page = 0;
      this.fetchProducts(val)
    },
    search (val) {
      if (val == '') {
        this.cat_products = []
      }
      this.page = 0;
      this.fetchProducts()
    }
  },
  computed: {
    filteredList () {
      return this.cat_products
      // .filter(pro => {
      //   if (pro.productname.toLowerCase().includes(this.search.toLowerCase())) {
      //     return pro.productname.toLowerCase().includes(this.search.toLowerCase());
      //   }

      //   if (pro.productcode !== null && pro.productcode.includes(this.search.toLowerCase())) {
      //     return pro.productcode && pro.productcode.includes(this.search.toLowerCase());
      //   }
      // });
    },
    shouldLoad () {
      return this.length != this.cat_products.length && !this.loading
    },
  },
  methods: {
    ...mapActions({
      'getProducts': 'index',
      'updateStockStatus': 'updateStockStatus',
      'updateStock': 'updateStock'
    }),

    async fetchProducts (index) {
      this.loading = true
      let vm = this;
      this.page += 1;
      index = this.index;
      this.availability = "0"
      let req = {
        what: "getProducts",
        useToken: true,
        params: {
          merchID: this.$store.getters.user.merchID,
          branchID: this.$store.getters.user.branchID,
          page: this.page,
          search: this.search
        }
      }

      if (this.page != 1) {
        req.params.categoryID = vm.categories[index].id;
      }
      await this.getProducts(req)
        .then(res => {
          let products = res;
          let cats = [];

          products.forEach(i => {
            cats.push({
              name: i.category,
              id: i.header.category,
              active: false
            })
            let urlParams = new URLSearchParams(i.header.Next);
            let next = urlParams.get('page')
          });
          if (this.page == 1) {
            vm.categories = cats;
          }
          this.all_products.push(...products);
          if (this.page == 1) {
            if (this.search == '') {
              products[index].header.data.forEach(product => {
                let index = vm.cat_products.findIndex(val => val.id == product.id)
                if (index == -1) {
                  vm.cat_products.push(product)
                } else {
                  vm.cat_products[index] = product
                }
              })
            } else {
              if (index >= products.length) {
                index = 0
              }
              if (products.length == 0) {
                vm.cat_products = []
              } else {
                vm.cat_products = products[index].header.data;
              }
            }
            this.length = products[index].header.Count
          }
          else {
            // if(this.search == ''){

            products[0].header.data.forEach(product => {
              let index = vm.cat_products.findIndex(val => val.id == product.id)
              if (index == -1) {

                vm.cat_products.push(product)
              } else {
                vm.cat_products[index] = product
              }
            })
            // }

            // vm.cat_products = vm.cat_products.concat(products[0].header.data);
            this.length = products[0].header.Count
          }
          vm.categories[index].active = true;

          // }
        })
        .catch(error => {
          // this.$swal.fire("Error", error.message, "error");
        });
      this.loading = false;
    },

    openModal (row) {
      if (this.$store.getters.businesscategory == 'Fashion' && row.productfeatures.length !== 0) {
        const grouped = this.groupBy(row.productfeatures, item => item.featuretype);
        row.variance = [];
        grouped.forEach((value, key) => {
          let data = {};
          data[key] = value
          row.variance.push(data)
        })

      }

      this.availability = row.outofstock == true ? "1" : "0";
      this.product = row;
    },
    groupBy (list, keyGetter) {
      const map = new Map();
      list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });
      return map;
    },
    editOrder () {
      this.edit = true;
    },
    formatPrice (price) {
      var str = price.toString().split(".");
      if (str[0].length >= 3) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      }
      if (!str[1]) {
        str[1] = "00";
      }
      return str.join(".");
    },
    activeCategory (value, index) {
      let vm = this;
      this.index = index;
      this.all_products.forEach(i => {
        if (i.category === value.name) {
          vm.cat_products = i.header.data;
        }
      })
    },
    editProduct () {

      if (this.edittype.includes('outofstock') || this.edittype.includes('avail')) {
        let req = {
          what: 'setProduct',
          useToken: true,
          data: {
            id: this.product.id,
            outofstock: this.availability
          }
        }
        this.updateStockStatus(req)
          // this.$request.makePostRequest(req)
          .then(res => {
            // if (res.type == 'setProduct') {
            if (this.inwardqty !== '') {
              this.addStock()
            }
            else {
              this.page = Math.ceil((this.cat_products.findIndex(product => product.id == this.product.id) + 1) / 10) - 1

              this.fetchProducts(this.index).then(() => {
                this.$swal.fire({
                  icon: 'success',
                  text: 'Product updated successfully'
                }).then((result) => {
                  this.edittype = [];
                  this.product = this.cat_products.find(product => product.id == this.product.id)
                  $(".modal").modal("hide");
                  // this.all_products = [];

                })
              })

            }


            // }
          })
          .catch(error => {
            // console.log('failing now')
            console.log(error)
            this.$swal.fire("Error", error, "error");
          });

      }
      else {
        this.addStock()
      }
    },
    onShare () {
      //test
      let url = 'https://ntisawebapp.wajesmarthrms.website/product/';

      //live
      // let url = 'https://checkout.ntisa.com.ng/product/';

      let link = url + this.$store.getters.user.businessdescription.toLowerCase() + '/' + this.$store.getters.user.merchID + '/' + this.product.branchID + '/' + this.product.productname.toLowerCase();
      this.product_link = encodeURI(link);
      $('#sharemodal.modal').modal("show");
    },
    onCopy () {
      let testingCodeToCopy = document.querySelector('#product_link')
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successfully' : 'unsuccessful';
        this.$toasted.show('Product link copied to Clipboard', {
          theme: "bubble",
          type: 'success',
          position: "top-right",
          duration: 3000,
        });
      } catch (err) {
        this.$toasted.show('Oops, unable to copy. Try Again!!!', {
          theme: "bubble",
          type: 'error',
          position: "top-right",
          duration: 3000,
        });
      }

      /* unselect the range */
      window.getSelection().removeAllRanges()
      $('#sharemodal.modal').modal("hide");
    }
    ,
    addStock () {
      let req = {
        what: 'addstock',
        useToken: true,
        data: {
          productID: this.product.id,
          merchID: this.$store.getters.user.merchID,
          branchID: this.$store.getters.user.branchID,
          inwardqty: this.inwardqty
        }
      }

      this.updateStock(req)
        // this.$request.makePostRequest(req)
        .then(res => {
          // if (res.type == 'addstock') {
          this.page = Math.ceil((this.cat_products.findIndex(product => product.id == this.product.id) + 1) / 10) - 1

          this.fetchProducts(this.index).then(() => {
            this.$swal.fire({
              icon: 'success',
              text: res.message
            }).then((result) => {
              this.inwardqty = '';
              this.product = this.cat_products.find(product => product.id == this.product.id)
              $(".modal").modal("hide");
            })
          })

          // }
        })
        .catch(error => {
          console.log(error);
          this.$swal.fire("Error", error.message, "error");
        });
      this.edittype = [];
    },

  }
}
</script>
<style scoped>
.variance {
  color: var(--navy-blue);
  text-align: left;
  font: normal normal 600 14px/18px Source Sans Pro;
  letter-spacing: 0px;
}
.pro-img {
  height: 200px;
}
.pro-img img {
  height: 100%;
}
#share-btn {
  background: #03a9f4;
  padding: 10px 10px;
  height: 45px;
}
button[disabled] {
  opacity: 0.65;
  cursor: not-allowed;
}
</style>
