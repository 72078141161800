<template>
  <div id="history">
    <Sidemenu />
    <div id="main">
      <Topmenu />

      <div class="filter-container ">
        <div class="row h-100 mx-1">
          <div class="col-xl-5 col-lg-4 my-auto filter-title">
            <div class="d-flex">
              <h5 class="title mr-2">Orders list</h5>
              <p class="subtitle pl-2">{{historydata.length}} Orders</p>
            </div>
          </div>
          <div class="col-xl-7 col-lg-8 my-auto filter-form">
            <!-- <div class="d-flex justify-content-between flex-wrap">
            <div
              id="search"
              class="my-auto "
            >
              <input
                v-model="search"
                class="form-control "
                type="text"
                placeholder="Search by customer’s name and order code"
              >
              <span class="fa fa-search"></span>
            </div>
            <select
              name=""
              id=""
              v-model="filter"
              class="form-control  my-auto"
            >
              <option
                value=""
                selected
                hidden
              >Filter by status</option>
              <option value="">All</option>
              <option value="PENDING">Pending</option>
              <option>Dispatched</option>
              <option>Scheduled</option>
              <option>Order Accepted</option>
              <option value="COMPLETED">Completed</option>
            </select>
         
          </div> -->
          </div>
        </div>
      </div>

      <div class="main-content mr-sm-3 my-4">
        <div class="card m-sm-4 p-2">

          <v-client-table
            :data="historydata"
            :columns="columns"
            :options="options"
            slots="slots"
            @row-click="viewOrder"
          >

            <span
              slot="id"
              slot-scope="props"
              class="d-flex cus-img flex-wrap"
            >
              <img
                v-if="props.row.profilelink ==null || props.row.profilelink ==''"
                class="mr-2 img-fluid img-responsive"
                src="../assets/img/customer-avatar.png"
                alt=""
              >
              <img
                v-else
                class="mr-2 img-fluid img-responsive"
                :src="props.row.profilelink"
                alt=""
              >
            </span>
            <span
              slot="customer"
              v-if="props.row.firstname !== ''"
              slot-scope="props"
            >{{props.row.firstname}} {{props.row.lastname}}</span>
            <span
              slot="customer"
              v-else
            >N/A</span>
            <span
              slot="phonenumber"
              slot-scope="props"
            >
              <span v-if="props.row.phonenumber !== ''">
                {{props.row.phonenumber}}</span>
              <span v-else>N/A</span>
            </span>
            <span
              slot="ordertotal"
              slot-scope="props"
            >{{formatPrice(props.row.ordertotal)}}</span>
            <span
              slot="rider"
              slot-scope="props"
            >
              <span v-if="props.row.riderID !== null">
                {{props.row.riderfirstname}} {{props.row.riderlastname}}</span>
              <span v-else>N/A</span>
            </span>

            <p
              slot="orderstatus"
              slot-scope="props"
            >
              <span
                v-if="props.row.orderstatus == 'completed'|| props.row.orderstatus == 'COMPLETED'"
                class="badge badge-pill badge-delivered"
              > {{props.row.orderstatus}}</span>
              <span
                v-else-if="props.row.orderstatus == 'pending' || props.row.orderstatus == 'paylater'"
                class="badge badge-pill badge-pending"
              > {{props.row.orderstatus}}</span>
              <span
                v-else-if="props.row.orderstatus == 'ready_for_dispatch'"
                class="badge badge-pill badge-delayed"
              > {{props.row.orderstatus}}</span>
              <span
                v-else-if="props.row.orderstatus == 'in_transit'"
                class="badge badge-pill badge-accepted"
              > {{props.row.orderstatus}}</span>
              <span
                v-else-if="props.row.orderstatus == 'cancel'"
                class="badge badge-pill badge-cancelled"
              > {{props.row.orderstatus}}</span>
              <span
                v-else-if="props.row.orderstatus == 'assigned' || props.row.orderstatus == 'assigned_to_kitchen'"
                class="badge badge-pill badge-scheduled"
              > {{props.row.orderstatus}}</span>
              <span
                v-else
                class="badge badge-pill"
              > {{props.row.orderstatus}}</span>

            </p>

            <span
              slot="transactiondate"
              slot-scope="props"
            >{{formatDate(props.row.transactiondate)}}</span>
          </v-client-table>

        </div>
      </div>
    </div>

    <!-- order modal -->
    <div
      v-if="Object.keys(order).length >0"
      id="ordermodal"
      class="modal"
      tabindex="-1"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header  mx-sm-5">
            <h5 class="modal-title ">Order Details

              <span
                v-if="order.orderstatus == 'completed'|| order.orderstatus == 'COMPLETED'"
                class="badge badge-pill badge-delivered"
              > {{order.orderstatus}}</span>
              <span
                v-else-if="order.orderstatus == 'pending' || order.orderstatus == 'paylater'"
                class="badge badge-pill badge-pending"
              > {{order.orderstatus}}</span>
              <span
                v-else-if="order.orderstatus == 'ready_for_dispatch'"
                class="badge badge-pill badge-delayed"
              > {{order.orderstatus}}</span>
              <span
                v-else-if="order.orderstatus == 'in_transit'"
                class="badge badge-pill badge-accepted"
              > {{order.orderstatus}}</span>
              <span
                v-else-if="order.orderstatus == 'cancel'"
                class="badge badge-pill badge-cancelled"
              > {{order.orderstatus}}</span>
              <span
                v-else-if="order.orderstatus == 'assigned' || order.orderstatus == 'assigned_to_kitchen'"
                class="badge badge-pill badge-scheduled"
              > {{order.orderstatus}}</span>

              <span
                v-else
                class="badge badge-pill"
              > {{order.orderstatus}}</span>

            </h5>

            <img
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              src="../assets/img/close.png"
              alt=""
            >
          </div>
          <div class="modal-body mx-sm-5">
            <div class="orderinfo d-flex justify-content-between flex-wrap my-4">
              <div>
                <h5 class="heading">Order ID</h5>
                <p class="id">{{order.ordercode}}</p>
                <p class="status">{{order.ordertype}}</p>
              </div>
              <div class="d-flex ml-3">
                <div class="m-auto cus-img ">
                  <img
                    v-if="order.profilelink ==null"
                    src="../assets/img/avatar.png"
                    alt=""
                    class="img-fluid img-responsive"
                  >
                  <img
                    v-else
                    :src="order.profilelink"
                    alt=""
                    class="img-fluid img-responsive"
                  >
                </div>
                <div class="ml-2">
                  <h5 class="heading">Customer info</h5>
                  <p class="value">{{order.firstname}} {{order.lastname}}</p>
                  <p class="phone">{{order.customerIdentifier != undefined ? order.customerIdentifier : order.phonenumber}}</p>
                </div>
              </div>
              <div>
                <h5 class="heading">Delivery address</h5>
                <p class="value">{{order.deliveryaddress}}</p>
              </div>

            </div>
            <div
              v-if="businesscategory =='Delivery'"
              class="orderinfo d-flex justify-content-between flex-wrap my-4"
            >
              <div>
                <h5 class="heading">Sender</h5>
                <p class="value">{{order.sendername}}</p>
                <p class="value">{{order.senderphone}}</p>
              </div>
              <div>
                <h5 class="heading">Receiver</h5>
                <p class="value">{{order.receivername}}</p>
                <p class="value">{{order.receiverphonenumber}}</p>
              </div>
              <div>
                <h5 class="heading">Pickup address</h5>
                <p class="value">{{order.pickupaddress}}</p>
              </div>
            </div>
            <table class="table my-5">

              <tbody>
                <tr
                  v-for="(row, ix) in order.orderitems"
                  v-bind:key=ix
                  class="items"
                >

                  <td class>
                    <img
                      v-lazy="row.photo"
                      alt=""
                      class="orderimg img-fluid img-responsive"
                    >
                  </td>
                  <td>
                    <h5 class="product">{{row.productname}}</h5>
                    <p class="price">₦ {{formatPrice(row.unitprice)}}</p>
                  </td>
                  <td>
                    <p class="qty">{{row.quantity}}</p>
                  </td>
                  <td class="subtotal">₦ {{formatPrice(row.totalprice)}}</td>

                </tr>

              </tbody>
            </table>
            <hr>

            <table class="table table-borderless w-sm-50 float-right">

              <tbody v-if="businesscategory !=='Delivery'">
                <tr>
                  <td>Sub total</td>
                  <td class="text-right">₦ {{formatPrice(order.ordertotal)}}</td>
                </tr>
                <tr>
                  <td>Delivery Charge</td>
                  <td class="text-right">₦ {{ order.deliverycharge != undefined ? formatPrice(order.deliverycharge) : 0}}</td>
                </tr>

              </tbody>
              <tfoot>
                <tr>
                  <th scope="col">Total</th>
                  <th
                    scope="col"
                    class="text-right"
                  >₦ {{formatPrice(order.ordertotal)}}</th>
                </tr>
              </tfoot>
            </table>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import * as $ from "jquery";
import Sidemenu from '@/components/sidemenu'
import Topmenu from '@/components/topmenu'
import { createNamespacedHelpers } from 'vuex'
import db from '@/DB.js'
   const { mapGetters, mapActions } = createNamespacedHelpers('orders')
export default {
  name: 'History',
  components: {
    Sidemenu, Topmenu
  },
  data () {
    return {
      search: '',
      filter: '',
      businesscategory: '',
      all_history: [],
      historydata: [],
      filterText: '',
      order: {},
      columns: ["id", "customer", "phonenumber", 'ordercode', 'ordertype', 'ordertotal', "rider", "orderstatus", 'transactiondate'],
      options: {

        headings: {
          id: '',
          customer: "Customer",
          phonenumber: 'Mobile',
          ordercode: 'OrderID',
          ordertype: 'Order Type',
          ordertotal: "Amount",
          orderstatus: 'Order Status',
          transactiondate: "Order Date",

        },

        sortable: ["id", "customer", "phonenumber", 'ordercode', 'ordertype', 'ordertotal', "rider", "orderstatus", 'transactiondate'],
        filterable: ["id", "firstname", 'lastname', "phonenumber", 'ordercode', 'ordertype', 'ordertotal', "rider", "orderstatus", 'transactiondate'],
        saveState: true,
        sortIcon:
        {
          base: 'fa',
          is: 'fa-sort',
          up: 'fa-long-arrow-up',
          down: 'fa-long-arrow-down'
        },
      },
    }
  },
  created () {
    this.fetchHistory();
    this.businesscategory = this.$store.getters.businesscategory
  },
  computed: {
    ...mapGetters({
      // 'all_history' : 'orderHistory',
      'queue' : 'queue'
      // 'host' : 'host'
    }),
    filteredList () {
      return this.historydata.filter(order => {

        if (order.firstname.toLowerCase().includes(this.search.toLowerCase())) {
          return order.firstname.toLowerCase().includes(this.search.toLowerCase());
        }
        else if (order.lastname.toLowerCase().includes(this.search.toLowerCase())) {
          return order.lastname.toLowerCase().includes(this.search.toLowerCase());
        }
        else {
          return order.ordercode.toLowerCase().includes(this.search.toLowerCase());
        }
      });
    }
  },
  watch: {
    filter: function (val) {
      let filteredHistory = []
      if (val == "") {
          filteredHistory = this.all_history
        } else {
          this.all_history.forEach(i => {
            if (i.orderstatus == val) {
              filteredHistory.push(i)
            }
            // else 
          });
        }
      this.historydata = filteredHistory
      // console.log(filteredHistory)
    }
  },
  methods: {
    ...mapActions({
      'index' : 'index',
      'getHistory' : 'getHistory'
      // 'host' : 'host'
    }),
    async fetchHistory () {
      // this.historydata = this.orderHistory

      let req = {
        what: "getHistory",
        useToken: true,
        showLoader: true,
        params: {
          merchID: this.$store.getters.user.merchID,
          branchID: this.$store.getters.user.branchID,
          orderstatus: this.filterText,
        }
      }
      this.index(req)
        .then(async res => {
          // console.log(res)

          // let orders = state.allOrders;
        
          this.all_history = await this.getHistory()
          // console.log(this.all_history)
          this.historydata = this.all_history
          // this.historydata = orders;
        
          // this.historydata = this.all_history
          // this.historydata = res;
          // if (res.type == 'getOrders') {
          //   let result = res.data.data;
          //   // this.all_orders.push(...result);
          //   this.orders = result;
          // }
          // this.$swal.close()
        })
        .catch(error => {
          if (error.message !== "Request failed with status code 404") {
            this.$swal.fire("Error", error.message, "error");
          }
        });
      // let req = {
      //   what: "getHistory",
      //   useToken: true,
      //   params: {
      //     merchID: this.$store.getters.user.merchID
      //   }
      // }
      // this.$request.makeGetRequest(req)
      //   .then(res => {
      //     if (res.type == 'getHistory') {
      //       this.all_history = res.data.data
      //       this.historydata = res.data.data
      //     }

      //   })
      //   .catch(error => {
        //   this.$swal.fire("Error", error.message, "error");
        // });
    },
    viewOrder (event) {
      $("#ordermodal").modal("show");
      this.order = event.row;
    },
    formatPrice (price) {
      var str = price.toString().split(".");
      if (str[0].length >= 3) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      }
      if (!str[1]) {
        str[1] = "00";
      }
      return str.join(".");
    },
    formatDate (date, format) {
      if (date !== null) {
        let d = new Date(date)
        if (format == 'time') {

          return d.toLocaleTimeString('en-US');
        }
        else if (format == 'date') {
          return d.toDateString().replace(' ', ', ');
        }
        else {
          return d.toDateString().replace(' ', ', ') + ' ' + d.toLocaleTimeString('en-US');
        }
      }
      else {
        return null
      }
    },

  }
}
</script>
<style scoped>
.cus-img {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  border: 1px solid #dee2e6;
  /* padding: 3px; */
}
.cus-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
@media screen and (max-width: 425px) {
  .main-content .card {
    margin: 0 10px;
  }
}
tr.VueTables__row {
  cursor: pointer;
}
.VueTables table.table-striped tbody tr:nth-of-type(odd) {
  background-color: transparent;
}
</style>
