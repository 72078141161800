
<template>
  <div id="invoice-POS">
    <center id="top">
      <div>
        <img
          :src="user.branchimage"
          alt=""
          style="height:50px "
          class="img-fluid mb-2"
        >
      </div>
      <div class="info">
        <h2>{{user.businessname}}</h2>
        <h5>{{user.branchname}}</h5>
      </div>
    </center>
    <div id="mid">
      <div class="info my-2">

        <p>
          Order Code : {{ordercode}}<br />
          Date/Time : {{formatDate(Date())}}<br />
        </p>
      </div>
    </div>
    <div id="bot my-2">
      <div id="table">
        <table>
          <tr class="tabletitle">
            <td class="item">
              <h3>Item</h3>
            </td>
            <td class="Hours">
              <h3 class="text-center">Qty</h3>
            </td>

          </tr>
          <tr
            class="service"
            v-for="(item, index) in cart"
            v-bind:key="index"
          >
            <td class="tableitem">
              <p class="itemtext">{{item.name}}</p>
            </td>
            <td class="tableitem">
              <p class="itemtext text-center">{{item.quantity}}</p>
            </td>

          </tr>
        </table>
      </div>
      <!--End Table-->
      <div id="legalcopy">
        <p class="my-3 text-capitalize">Attended to by: {{user.firstname}} {{user.lastname}}</p>

      </div>
    </div>
    <!--End InvoiceBot-->
  </div>

</template>

<script>
import "../assets/css/receipt.css"
export default {
  data () {
    return {
      user: null,
      order: null,
      cart: null,
      ordercode: "",
      printer_off: true,
    }
  },


  created () {
    this.user = this.$store.getters.user;
    let cart = this.$store.getters.cart;
    this.cart = cart;
    this.order = this.$store.getters.order;
    this.ordercode = this.$store.getters.ordercode;
  },
  mounted () {
    this.printer_off = false;

  },
  watch: {
    printer_off (val) {
      let vm = this
      if (val == true) {
        // this.$store.dispatch('updateCart', []);
        // this.$router.go(-1);
      }
      else {
        setTimeout(() => {
          window.print();
        }, 3000)
        let isMobile = this.detectMob()
        if (isMobile) {
          if (window.matchMedia) {
            var mediaQueryList = window.matchMedia('print');
            mediaQueryList.addEventListener("change", (mql) => {
              if (mql.matches) {
                // console.log('before')
              } else {
                vm.$store.dispatch('updateCart', []);
              }
            });
          }
        }
        else {
          window.onafterprint = function (event) {
            vm.$store.dispatch('updateCart', []);
            // vm.$router.push('/walk-in')
            vm.$router.go(-1)
          };

        }
      }
    }
  },

  methods: {
    detectMob () {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ];

      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });
    },
    formatDate (date, format) {
      if (date !== null) {
        let d = new Date(date)
        if (format == 'time') {

          return d.toLocaleTimeString('en-US');
        }
        else if (format == 'date') {
          return d.toDateString().replace(' ', ', ');
        }
        else {
          return d.toDateString().replace(' ', ', ') + ' ' + d.toLocaleTimeString('en-US');
        }
      }
      else {
        return null
      }
    },


  }
};
</script>
<style scoped>
</style>

