<template>
  <div id="FreeDelivery">
    <!--  -->
    <div
      v-if="within30days"
      class="text-center promotion_alert_header"
    >
      YOUR SUBSCRIPTION EXPIRES <span class="countdown_left blinking">{{countdown_left}}</span>
    </div>
    <div
      v-else-if="expired"
      class="text-center promotion_alert_header_red"
    >
      <span class="countdown_left blinking">YOUR SUBSCRIPTION HAS EXPIRED</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'countdown',
  data () {
    return {
      within30days: false,
      countdown_left: "",
      expired: false,
    }
  },
  computed: {
    today () {
      return new Date();
    },
    license () {
      return this.$store.getters.getLicense;
    }
  },
  mounted () {
    // console.log('countdown')
    //=>> Set date variables here.

    // let startstring     = "April 25, 2021";       // set start date here   >>>
    // let futurestring    = "May 3, 2021 23:59:59"; // set future date here; >>>
    let futurestring = this.license; // set future date here; >>>
    // console.log(Date.parse(futurestring))
    //=>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    //=>>Format todays date below. - untouched!
    let montharray = new Array("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec");
    // let today           = new Date();
    let today_y = this.today.getFullYear();
    let today_m = this.today.getMonth();
    let today_d = this.today.getDate();
    let today_h = this.today.getHours();
    let today_min = this.today.getMinutes();
    let today_sec = this.today.getSeconds();
    let todaystring = montharray[today_m] + " " + today_d + ", " + today_y + " " + today_h + ":" + today_min + ":" + today_sec;

    //=> Parse date and get diffrences below;
    let dd = Date.parse(futurestring) - Date.parse(todaystring);
    // console.log(dd)
    let dday = Math.floor(dd / (60 * 60 * 1000 * 24) * 1);
    // console.log(dday)
    let dhour = Math.floor((dd % (60 * 60 * 1000 * 24)) / (60 * 60 * 1000) * 1);
    let dmin = Math.floor(((dd % (60 * 60 * 1000 * 24)) % (60 * 60 * 1000)) / (60 * 1000) * 1);
    let dsec = Math.floor((((dd % (60 * 60 * 1000 * 24)) % (60 * 60 * 1000)) % (60 * 1000)) / 1000 * 1);
    //=> Get time as milliseconds.
    let today_time = this.today.getTime();
    // let start_time      = new Date(startstring).getTime();
    let end_time = new Date(futurestring).getTime();


    if (dday <= 30) {
      this.within30days = true;
      if (dday < 0) {
        this.within30days = false;

        this.countdown_left = " in " + dday + " days.";
        this.expired = true
      } else if (dday == 0) {
        this.countdown_left = "today.";
      }
      else if (dday == 1) {
        this.countdown_left = "tomorrow.";
      }
      else {
        this.countdown_left = " in " + dday + " days.";
      }
    } else {
      this.within30days = false;
    }

  }
}
</script>

<style scoped>
.countdown_left {
  font: normal normal normal 16px/22px Source Sans Pro;
  /* background: transparent; */
  color: red;
}
.promotion_alert_header {
  font: normal normal normal 14px/20px Source Sans Pro;
  letter-spacing: 0px;
  color: red;
}

/* .promotion_alert_header_red {
    font: normal normal normal 14px/20px Source Sans Pro;
    letter-spacing: 0px;
    color: red;
} */

.blinking {
  animation: blinkingText 7.5s infinite;
}
@keyframes blinkingText {
  0% {
    color: red;
    background: transparent;
  }
  59% {
    color: red;
    background: transparent;
  }
  100% {
    color: red;
    background: transparent;
  }
}
@media screen and (max-width: 500px) {
  .countdown_left {
    margin-left: 3px;
    font-size: 14px !important;
  }
  .promotion_alert_header {
    font-size: 12px;
  }
}
</style>