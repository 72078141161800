<template>
  <div id="orderprocess">
    <div class="">
      <div class="p-0">
        <Sidemenu />
        <Topmenu />

        <div class="main-content">
          <div class="row my-5  mx-sm-3">
            <div class="col-md-9">
              <div class="card">
                <div class="card-body">

                  <table
                    id="cart-table"
                    class="table table-hover table-responsive-sm"
                  >
                    <thead class="border-0">
                      <th>S/N</th>
                      <th>Image</th>
                      <th>Product Name</th>
                      <th>Unit Price</th>
                      <th>Quantity</th>
                      <th>Sub Total</th>
                    </thead>
                    <tbody class="border-0">
                      <tr
                        v-for="(row,index) in cart"
                        v-bind:key="index"
                        class="items"
                      >
                        <td>{{index+1}}</td>
                        <td>
                          <img
                            :src="'http://68.65.121.57:8080/'+row.image"
                            alt=""
                            class="orderimg"
                          >

                        </td>
                        <td> <span class="product ml-3">{{row.name}}</span></td>
                        <td>
                          <span class="price">₦ {{row.price}}</span>
                        </td>
                        <td>
                          <span class="qty">{{row.qty}}</span>

                        </td>
                        <td class="subtotal">₦ {{formatPrice(row.total)}}</td>
                        <td>
                          <span
                            :id="'badge'+index"
                            class="badge bg-success text-white"
                          >Checked Out</span>
                          <img
                            data-toggle='modal'
                            data-target="#barcode"
                            @click="scanCode(row)"
                            :id="'img'+index"
                            title="checkout"
                            class="ml-2 checkout-btn"
                            src="../assets/img/check.png"
                            alt=""
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title text-bold">Cart Total</h5>
                  <table class="table table-sm mt-5">

                    <tbody>
                      <tr>
                        <td>Subtotal</td>
                        <td class="float-right">₦{{formatPrice(cart_subtotal)}}</td>
                      </tr>

                    </tbody>
                    <tfoot>
                      <th>Total</th>
                      <th class="float-right">₦{{formatPrice(cart_total)}}</th>
                    </tfoot>
                  </table>
                  <div class="my-4 total d-flex justify-content-between">

                    <button
                      class="btn"
                      @click="handleCheckout"
                    >Place Order</button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- barcode modal -->
      <div
        id="barcode"
        class="modal"
        tabindex="-1"
        data-backdrop='static'
      >
        <div class="modal-dialog modal-sm">
          <div class="modal-content">
            <div class="modal-header  mx-2 border-0">

              <img
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                src="../assets/img/close.png"
                alt=""
              >
            </div>
            <div class="modal-body mx-2 text-center">
              <img :src="codeLink" />

            </div>
            <div class="modal-footer mx-2 border-0">

              <button
                @click.prevent="handleCheckout()"
                type="button"
                class="btn ml-2 my-auto"
              >Checkout</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as $ from "jquery";
import Sidemenu from '@/components/sidemenu'
import Topmenu from '@/components/topmenu'
export default {
  name: 'OrderProcess',
  components: {
    Sidemenu, Topmenu
  },
  data () {
    return {
      selected: {},
      edit: false,
      cart: [],
      codeLink: '',
      cart_total: 0,
    }
  },
  created () {
    this.fetchCart()
  },
  computed: {
    cart_subtotal () {
      let total = 0;
      this.cart.forEach(i => {
        total += Number(i.price);
      })
      this.cart_total = total;
      return total;
    }
  },
  methods: {
    fetchCart () {
      this.cart = this.$store.getters.cart;
    },
    scanCode (row) {
      this.selected = row;
      this.codeLink = "https://barcodes4.me/barcode/c128b/" + row.productID + ".png";
    },
    handleCheckout () {
      this.cart.forEach((i, index) => {
        if (i.productID == this.selected.productID) {
          document.getElementById("img" + index).style.display = "none"
          document.getElementById("badge" + index).style.display = "block"
        }
      })
      $(".modal").modal("hide");
    },
    formatPrice (price) {
      var str = price.toString().split(".");
      if (str[0].length >= 3) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      }
      if (!str[1]) {
        str[1] = "00";
      }
      return str.join(".");
    },
  }
}
</script>
<style scoped>
#app {
  min-width: 1440px;
  overflow: auto;
}
th {
  border-top: 0 !important;
  border-bottom: 0 !important;
}
table img.orderimg {
  width: 80px;
}
.checkout-btn {
  display: none;
}
.badge {
  display: none;
}
tr:hover .checkout-btn,
tr:focus .checkout-btn {
  display: block;
}
@media screen and (max-width: 1024px) {
  #app {
    min-width: 1440px;
    overflow: auto;
  }
}
/*
@media screen and (max-width: 1024px) and (min-width: 993px) {
}
@media screen and (max-width: 992px) and (min-width: 769px) {
}
@media screen and (max-width: 768px) and (min-width: 426px) {
}
@media screen and (max-width: 425px) {
} */
@media screen and (max-width: 768px) {
  .pro {
    width: auto;
    padding: 20px;
  }
}
</style>


