/*
	This script make requests through a NodeJS proxy.
	The other variation of this script makes requests directly to the URL defined.

*/

import Vue from "vue";
import store from "./store";
import axios from 'axios'
import Utils from '@/utils.js'
axios.defaults.timeout = 180000000000000000000000000000;
//test
// const URL = "http://68.65.121.57:8080/API/";
const URL = "https://wajeloyalty.website/API/";
//deployment
// const URL = "http://162.0.233.96/API/";
// live
// const URL = "https://ntisa.website/API/";

export default {
    name: "Request",

    closeLoader() {
        if (Vue.prototype.$swal.isVisible()) {
            Vue.prototype.$swal.close()
        }
    },

    makeGetRequest: (request, request_token) => {
        if ((request.showLoader || request.showLoader == undefined) && Vue.prototype.$swal != undefined) {
            if (!Vue.prototype.$swal.isVisible()) {
                var loading_html =
                    '<div style="height:150px;width:150px;margin: 0 auto;"><img style="width: 100%;" src="https://trails.ca/wp-content/uploads/2020/10/loading-spinner.gif" /></div>';

                Vue.prototype.$swal.fire({
                    title: "",
                    html: loading_html,
                    showConfirmButton: false,
                    showCancelButton: false
                });
            }
        }



        var request_urls = {
            login: 'user/',
            getOrders: 'onlineplaceorder/',
            getProducts: 'listmenu/',
            getHistory: 'placeorder/',
            getRiders: 'rider/',
            getCustomers: 'offlinecustomer/',
            searchCustomer: 'customerrecord/',
            getRewards: 'rewardloyalty/',
            barcodescan: 'barcodesearch/',
            summary: 'storeattendantdailyreport/',
            getUsers: 'merchantusers/',
            queue: 'queueorder/',
            merchantsubscription: 'merchantsubscription/',
            networkstatus: 'networkstatus/'

        };
        if (request.params !== undefined) {
            var obj = request.params;
            var str = Object.keys(obj)
                .map(key => {
                    return key + "=" + obj[key];
                })
                .join("&");
            var request_url = URL + request_urls[request.what] + "?" + str;
        } else if (request.id) {
            var request_url = URL + request_urls[request.what] + "" + request.id;
        } else {
            var request_url = URL + request_urls[request.what];
        }
        if (request.formData || request.useToken) {
            request.headers = {};
            if (request.formData) {
                request.headers = {
                    'Content-Type': 'multipart/form-data'
                }
            }
            if (request.useToken || request.useToken == undefined) {
                var token;
                if (request_token != undefined) {
                    token = request_token;
                } else {
                    token = store.getters.token;
                }
                request.headers.Authorization = "Bearer " + token
            }
        }
        var config = {};

        if (request.headers !== undefined) {
            config.headers = request.headers;
        }
        return new Promise(async(resolve, reject) => {
            let online = await Utils.checkOnlineStatus();
            let vm = this;

            // console.log(online);
            if (online || Utils.shouldGo(request.what) || (request.what == 'queue' && request.params.orderstatus == 'assigned_to_kitchen')) {
                // console.log(request.what)
                axios
                    .get(request_url, config)
                    .then(response => {
                        if (Vue.prototype.$swal != undefined && store.state.load == false && store.getters['posts/status'] == false) {
                            // console.log(store.state.load)
                            Vue.prototype.$swal.close()
                        }
                        if (response.status == "true" || response.status) {
                            var response = {
                                data: response.data,
                                type: request.what,
                                status: "true"
                            };

                            resolve(response);
                        } else {
                            reject(response.data.message);
                        }
                    })
                    .catch(err => {

                        // console.log(err)
                        if (Vue.prototype.$swal != undefined) {
                            Vue.prototype.$swal.close()
                        }
                        if (!err.response || err.code === 'ECONNABORTED') {
                            // vm.closeLoader();
                            // resolve({data: { status: true}})


                            reject({ message: "Please check your internet connection. This page does not work without internet" });
                        } else {
                            // vm.closeLoader();
                            reject(err);
                        }
                    });
            } else {
                if (Vue.prototype.$swal != undefined) {
                    Vue.prototype.$swal.close()
                }

                // vm.closeLoader();
                resolve({ data: { status: true } })
            }

            // vm.closeLoader()



        });
    },


    async makePostRequest(request, request_token) {
        let request_store = Object.assign({}, request);

        if ((request.showLoader || request.showLoader == undefined) && Vue.prototype.$swal != undefined) {
            var loading_html =
                '<div style="height:150px;width:150px;margin: 0 auto;"><img style="width: 100%;" src="https://trails.ca/wp-content/uploads/2020/10/loading-spinner.gif" /></div>';

            Vue.prototype.$swal.fire({
                title: "",
                width: 300,
                html: loading_html,
                showConfirmButton: false,
                showCancelButton: false
            });
        }
        var request_urls = {
            password: 'userpassword/',
            createcustomer: 'customerrecord/',
            order: 'placeweborder/',
            onlinedeliveryorder: 'placeorder/',
            deliveryorder: 'placewebdeliveryorder/',
            assignrider: 'assignorder/',
            calculatedelivery: 'deliveryfeecal/',
            onlinedeliveryfee: 'otherdeliveryfeecal/',
            redeem: 'redeemreward/',
            addstock: 'addstocks/',
            cancelorder: 'deliverystatus/',
            setProduct: 'setproduct/',
            processpickup: 'deliverystatus/',
            addToQueue: 'queueorder/',
            completequeueorder: 'changeorderstatus/',
            mergequeue: 'mergeorderstatus/',
            emailreceipt: 'emailreceipt/'

        };
        // let hash = await Utils.hashCode(JSON.stringify(request_store));


        if (request.key) {
            var request_url = URL + request_urls[request.what] + request.key;
        } else {
            var request_url = URL + request_urls[request.what];
            request_url += request.id == undefined ? "" : "" + request.id;
        }
        if (request.formData || request.useToken) {
            request.headers = {};
            if (request.formData) {
                request.headers = {
                    'Content-Type': 'multipart/form-data'
                }
            }
            if (request.useToken || request.useToken == undefined) {
                var token;
                if (request_token != undefined) {
                    token = request_token;
                } else {
                    token = store.getters.token;
                }
                request.headers.Authorization = "Bearer " + token
            }
        }
        var config = {};

        if (request.headers !== undefined) {
            config.headers = request.headers;
        }

        let vm = this;


        return new Promise(async(resolve, reject) => {

            let online = await Utils.checkOnlineStatus();
            // console.log(online);
            if (online || Utils.shouldGo(request.what)) {
                axios
                    .post(request_url, request.data, config)
                    .then(response => {

                        if (Vue.prototype.$swal != undefined && request.showLoader) {
                            Vue.prototype.$swal.close()
                        }

                        if (response.data.status == "true" || response.data.status || response.success == "true") {
                            response = {
                                data: response.data,
                                type: request.what,
                                status: "true"
                            };
                            // vm.closeLoader();
                            resolve(response);
                        } else {
                            // vm.closeLoader();
                            reject(response.data.message);
                        }
                    })
                    .catch(err => {
                        if (Vue.prototype.$swal != undefined) {
                            Vue.prototype.$swal.close()
                        }
                        // console.log(err)
                        if (!err.response || err.code === 'ECONNABORTED') {
                            // vm.closeLoader();
                            // resolve({data: { status: true}})


                            reject({ message: "Please check your internet connection. This function does not work without internet" });
                        } else {
                            // vm.closeLoader();
                            reject(err);
                        }

                        // vm.closeLoader();
                        // reject(err);
                    });
            } else {
                // console.log('adding new posts')
                // if (Vue.prototype.$swal.isVisible()) {
                if (Vue.prototype.$swal != undefined) {
                    Vue.prototype.$swal.close()
                }
                // }
                request_store.showLoader = false;
                store.dispatch('posts/addPost', request_store).then((hash_data) => {
                    if (Utils.checkShouldStressSync(request_store)) {
                        request_store.data.id = hash_data.hash;
                    }
                    // vm.closeLoader();

                    resolve({ data: { status: true, req: request_store }, })
                }).catch(err => {
                    // console.log(err)
                    // vm.closeLoader();
                    reject(err);
                });
            }

            // vm.closeLoader()

            // this.closeLoader();

        });
    },



    editItem: (request, request_token) => {

        let request_store = request;
        let vm = this;

        if ((request.showLoader || request.showLoader == undefined) && Vue.prototype.$swal != undefined) {
            var loading_html =
                '<div style="height:150px;width:150px;margin: 0 auto;"><img style="width: 100%;" src="https://trails.ca/wp-content/uploads/2020/10/loading-spinner.gif" /></div>';

            Vue.prototype.$swal.fire({
                title: "",
                html: loading_html,
                showConfirmButton: false,
                showCancelButton: false
            });
        }
        var request_urls = {
            profile: 'merchantusers/',
            editProduct: 'product/',
            editStatus: 'changeorderstatus/'
        };
        var request_url = URL + request_urls[request.what];
        request_url += request.id == undefined ? "" : "" + request.id + "/";

        if (request.formData || request.useToken) {
            request.headers = {};
            if (request.formData) {
                request.headers = {
                    'Content-Type': 'multipart/form-data'
                }
            }
            if (request.useToken || request.useToken == undefined) {
                var token;
                if (request_token != undefined) {
                    token = request_token;
                } else {
                    token = store.getters.token;
                }
                request.headers.Authorization = "Bearer " + token
            }
        }
        var config = {
            headers: request.headers
        };

        return new Promise(async(resolve, reject) => {
            let online = await Utils.checkOnlineStatus();
            if (!online && request.what == 'editProduct') {
                if (Vue.prototype.$swal != undefined) {
                    Vue.prototype.$swal.close()
                }
                request_store.showLoader = false;
                store.dispatch('posts/addPost', request_store).then(() => {
                    // vm.closeLoader();

                    resolve({ data: { status: true } })
                }).catch(err => {
                    // vm.closeLoader();
                    reject(err);
                });
            } else {
                axios
                    .put(request_url, request.data, config)
                    .then(response => {
                        // vm.closeLoader();
                        if (Vue.prototype.$swal != undefined) {
                            Vue.prototype.$swal.close()
                        }
                        if (response.data.status == "true" || response.data.status) {
                            response = {
                                data: response.data,
                                type: request.what,
                                status: "true"
                            };
                            resolve(response)
                        } else {
                            reject('error')
                        }
                    })
                    .catch(err => {
                        if (Vue.prototype.$swal != undefined) {
                            Vue.prototype.$swal.close()
                        }
                        // console.log(err)
                        if (!err.response || err.code === 'ECONNABORTED') {
                            // vm.closeLoader();
                            // resolve({data: { status: true}})


                            reject({ message: "Please check your internet connection. This function does not work without internet" });
                        } else {
                            // vm.closeLoader();
                            reject(err);
                        }
                        // reject(err)
                    });
            }

            // vm.closeLoader()

            // this.closeLoader();

        });
    },
    deleteItem: (request) => {

        let request_store = request;

        let vm = this;


        if ((request.showLoader || request.showLoader == undefined) && Vue.prototype.$swal != undefined) {
            if (!Vue.prototype.$swal.isVisible()) {
                var loading_html = '<div style="height:150px;width:150px;margin: 0 auto;"><img style="width: 100%;" src="https://i.ya-webdesign.com/images/minimalist-transparent-loading-gif-6.gif" /></div>'

                Vue.prototype.$swal.fire({
                    title: "",
                    html: loading_html,
                    showConfirmButton: false,
                    showCancelButton: false
                })
            }
        }

        var request_urls = {
            deleteaddress: 'addresses',
        }

        var request_url = URL + request_urls[request.what]

        request_url += (request.id == undefined) ? "" : "/" + request.id

        var config = {
            headers: {

            },
            data: request.data
        }

        return new Promise(async(resolve, reject) => {

            // let online = await Utils.checkOnlineStatus();
            // if(online) {
            axios.delete(request_url, config)
                .then((response) => {
                    if (Vue.prototype.$swal != undefined) {
                        Vue.prototype.$swal.close()
                    }

                    // this.closeLoader();


                    if (response.data.status == 'true' || response.data.status) {

                        response = {
                                data: response.data,
                                type: request.what,
                                status: 'true'
                            }
                            // vm.closeLoader();

                        resolve(response)
                    } else {
                        // vm.closeLoader();

                        reject(response)
                    }
                })
                .catch((err) => {
                    if (Vue.prototype.$swal != undefined) {
                        Vue.prototype.$swal.close()
                    }
                    // console.log(err)
                    if (!err.response || err.code === 'ECONNABORTED') {
                        // vm.closeLoader();
                        // resolve({data: { status: true}})


                        reject({ message: "Please check your internet connection. This function does not work without internet" });
                    } else {
                        // vm.closeLoader();
                        reject(err);
                    }
                    // vm.closeLoader();
                    reject(err)
                })
                // } else {
                //     request_store.showLoader = false;
                //     // if (Vue.prototype.$swal.isVisible()) {
                //         if(Vue.prototype.$swal != undefined) {
                //             Vue.prototype.$swal.close()
                //         }
                //     // }
                //     store.dispatch('posts/addPost', request_store).then(() => {
                //         // vm.closeLoader();
                //         resolve({data: { status : true}})
                //     }).catch(err => {
                //         // vm.closeLoader();
                //         reject(err);
                //     });
                // }

            // vm.closeLoader()

        })


    }
};