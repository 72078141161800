<template>
  <div id="summary">
    <Sidemenu />
    <div id="main">
      <Topmenu />
      <div class="filter-container">
        <div class="row h-100 mx-1">
          <div class="col-12 my-auto filter-title d-flex  justify-content-between flex-wrap">

            <h5 class="title mr-2 my-auto">Sales Summary For Today - <strong>{{formatDate(new Date(), 'date')}}</strong>
            </h5>
            <button
              class="btn mt-0"
              @click="print"
            >Print Summary</button>
          </div>
        </div>
      </div>
      <div class="main-content mr-sm-3 my-4">
        <div class="row">
          <div class="col-sm-6">
            <div class="card bigcard m-3 p-md-3 row">

              <div class="col-8 m-auto ">
                <h5>Total Orders</h5>
                <h4 class="mt-5">{{summarydetails.totalorder}}</h4>

              </div>
              <div class="col-4 mx-auto mt-4 text-center">
                <img
                  src="../assets/img/totalorders.png"
                  alt=""
                  class="img-responsive"
                >
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card bigcard second  m-3 p-md-3 row">

              <div class="col-8 m-auto">
                <h5>Total Sales</h5>
                <h4 class="mt-5">₦{{formatPrice(total)}}</h4>

              </div>
              <div class="col-4 mx-auto mt-4 text-center">
                <img
                  src="../assets/img/sales.png"
                  alt=""
                  class="img-responsive"
                >
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-sm-6">
            <div class="card  m-3 p-md-2 row">

              <div class="col-8 m-auto">
                <h5>Total Cash</h5>
                <h4 class="mt-5">₦{{formatPrice(summarydetails.totalcash)}}</h4>

              </div>
              <div class="col-4 mx-auto mt-4 text-center">
                <img
                  src="../assets/img/totalcash.png"
                  alt=""
                  class="img-responsive"
                >
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-sm-6">
            <div class="card  m-3 p-md-2 row">
              <div class="col-8 m-auto">
                <h5>Total Pos</h5>
                <h4 class="mt-5">₦{{formatPrice(summarydetails.totalpos)}}</h4>

              </div>
              <div class="col-4 mx-auto mt-4 text-center">
                <img
                  src="../assets/img/totalpos.png"
                  alt=""
                  class="img-responsive"
                >
              </div>

            </div>
          </div>

          <div class="col-lg-4 col-sm-6">
            <div class="card  m-3 p-md-2 row">
              <div class="col-8 m-auto">
                <h5>Total Bank Transfer</h5>
                <h4 class="mt-5">₦{{formatPrice(summarydetails.totaltransfer)}}</h4>

              </div>

              <div class="col-4 mx-auto mt-4 text-center">
                <img
                  src="../assets/img/totaltransfer.png"
                  alt=""
                  class="img-responsive"
                >
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- SOURCE -->
      <div
        id="printSummary"
        class="p-3 d-none"
        style="margin-left:10px; max-width: 280px;"
      >
        <div class=" mb-2">

          <h3
            class="title"
            style="font-family: Source Sans Pro;font-size: 24px;font-weight: 900;"
          >{{$store.getters.user.businessname}}</h3>
          <h5 style="font: normal normal 500 20px/24px Source Sans Pro;">{{$store.getters.user.branchname}}</h5>
          <h4
            class="mb-2"
            style="font: normal normal 900 20px/24px Source Sans Pro;margin-top: 14px;"
          ><u>DAILY SALES SUMMARY</u></h4>
          <h5
            class="title"
            style=" font: normal normal 500 20px/24px Source Sans Pro;"
          >Staff Name : <strong style="font: normal normal 900 20px/24px Source Sans Pro;">{{$store.getters.user.firstname}} {{$store.getters.user.lastname}}</strong></h5>
          <h5
            class="title"
            style=" font: normal normal 500 20px/24px Source Sans Pro;"
          >Date : <strong style="font: normal normal 900 20px/24px Source Sans Pro;">{{formatDate(new Date(), 'date')}}</strong>
          </h5>
          <hr>
        </div>

        <div class=" mb-4">
          <div class="d-flex mb-3">
            <h5 style="width:150px;font: normal normal 500 20px/24px Source Sans Pro;">Total Orders</h5>
            <h5 style="margin-left:10px;"><strong style="font: normal normal 900 20px/24px Source Sans Pro;">{{summarydetails.totalorder}}</strong></h5>
          </div>
          <div class="d-flex mb-3">
            <h5 style="width:150px;font: normal normal 500 20px/24px Source Sans Pro;">Total Cash</h5>
            <h5 style="margin-left:10px"><strong style="font: normal normal 900 20px/24px Source Sans Pro;">₦{{formatPrice(summarydetails.totalcash)}}</strong></h5>
          </div>
          <div class="d-flex mb-3">
            <h5 style="width:150px;font: normal normal 500 20px/24px Source Sans Pro;">Total Pos</h5>
            <h5 style="margin-left:10px"><strong style="font: normal normal 900 20px/24px Source Sans Pro;">₦{{formatPrice(summarydetails.totalpos)}}</strong></h5>
          </div>
          <div class="d-flex mb-3">
            <h5 style="width:150px;font: normal normal 500 20px/24px Source Sans Pro;">Total Bank <br> Transfer</h5>
            <h5 style="margin-left:10px"><strong style="font: normal normal 900 20px/24px Source Sans Pro;">₦{{formatPrice(summarydetails.totaltransfer)}}</strong></h5>
          </div>
          <hr>
          <div class="d-flex mb-3">
            <h5 style="width:150px;font: normal normal 500 20px/24px Source Sans Pro;">Total Sales</h5>
            <h5 style="margin-left:10px;"><strong style="font: normal normal 900 22px/24px Source Sans Pro;">₦{{formatPrice(total)}}</strong></h5>
          </div>

        </div>
      </div>
      <!-- OUTPUT -->

    </div>

  </div>
</template>

<script>
import Sidemenu from '@/components/sidemenu'
import Topmenu from '@/components/topmenu'
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('orders')
import Utils from '@/utils.js'
export default {
  name: 'Summary',
  components: {
    Sidemenu, Topmenu
  },
  data () {
    return {
      summarydetails: {
        totalorder: '0',
        totalpos: '0',
        totalcash: '0',
        totaltransfer: '0'
      }
    }
  },
  async created () {
    await this.fetchSummary()
  },
  computed: {
    // ...mapGetters({
    //   'orders' : 'orderHistory',
    // }),

    total () {
      return Number(this.summarydetails.totalcash) + Number(this.summarydetails.totalpos) + Number(this.summarydetails.totaltransfer)
    }
  },

  methods: {
    ...mapActions({
      'summary': 'getSummary',
    }),
    async getSummary () {
      // let today = new Date()
      // let tomorrow = new Date(today)
      // today.setHours(0, 0, 0);
      // tomorrow.setDate(tomorrow.getDate() + 1)
      // tomorrow.setHours(0, 0, 0);
      // today = today.getTime();
      // tomorrow = tomorrow.getTime();

      // // let date = new Date();

      // // console.log(this.$store.getters.user.id)

      // let orderList = this.orders.filter((order) => {
      //   if(order.staffID == this.$store.getters.user.id) {
      //     if (order.paymentstatus == 'PAID'){
      //       if (['Receiver', 'instore', 'Sender'].includes(order.ordertype)) {
      //         // let date = Date.parse(order.created_at)
      //         // if(today <= date && date <= tomorrow) {
      //           return true;
      //         // }
      //       }
      //     }
      //   }
      //   return false;
      // });

      let orderList = (await this.summary(this.$store.getters.user.id))


      this.summarydetails.totalorder = orderList.length;
      // let result =   this.$store.getters['orders/offlineOrders']('completed')

      this.summarydetails.totalcash = orderList.map(order => order.multiple != undefined ? order.multiple : order.paymentdetails).filter((payment) => payment.find(pay => pay.paymentmethod == 'cash') != null).map((payment) => payment.find(pay => pay.paymentmethod == 'cash').paymentamount).reduce((acc, curr) => {
        return Number(acc) + Number(curr);
      }, 0)
      this.summarydetails.totalpos = orderList.map(order => order.multiple != undefined ? order.multiple : order.paymentdetails).filter((payment) => payment.find(pay => pay.paymentmethod == 'pos') != null).map((payment) => payment.find(pay => pay.paymentmethod == 'pos').paymentamount).reduce((acc, curr) => {
        return Number(acc) + Number(curr);
      }, 0)
      this.summarydetails.totaltransfer = orderList.map(order => order.multiple != undefined ? order.multiple : order.paymentdetails).filter((payment) => payment.find(pay => pay.paymentmethod == 'banktransfer') != null).map((payment) => payment.find(pay => pay.paymentmethod == 'banktransfer').paymentamount).reduce((acc, curr) => {
        return Number(acc) + Number(curr);
      }, 0)
    },
    async fetchSummary () {
      if (await Utils.checkOnlineStatus()) {
        let req = {
          what: "summary",
          useToken: true,
          params: {
            merchID: this.$store.getters.user.merchID,
            branchID: this.$store.getters.user.branchID,
            attendantID: this.$store.getters.user.id,
          }
        }
        this.$request.makeGetRequest(req)
          .then(res => {
            if (res.type == 'summary') {
              this.summarydetails = res.data.data
            }
          })
          .catch(error => {
            this.$swal.fire("Error", error.message, "error");
          });
      } else {
        this.getSummary();
      }
    },
    formatPrice (price) {
      if (price == null) {
        return '0.00';
      }
      else {

        var str = Number(price).toFixed(2).toString().split(".");
        if (str[0].length >= 3) {
          str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
        }
        if (!str[1]) {
          str[1] = "00";
        }
        return str.join(".");
      }


    },
    formatDate (date, format) {
      if (date !== null) {
        let d = new Date(date)
        if (format == 'time') {

          return d.toLocaleTimeString('en-US');
        }
        else if (format == 'date') {
          return d.toDateString().replace(' ', ', ');
        }
        else {
          return d.toDateString().replace(' ', ', ') + ' ' + d.toLocaleTimeString('en-US');
        }
      }
      else {
        return null
      }
    },
    print () {
      this.$store.dispatch('summary', this.summarydetails)
        .then(() => {
          this.$router.push('/print-summary')
        })

    }
  }
}
</script>
<style scoped>
</style>
