<template >
  <div
    id="security"
    class=""
  >
    <div class="header py-4 border-bottom">
      <h4>Verified Information</h4>
      <p>Your verified information helps us secure your account.</p>
    </div>
    <div class="page-body my-5">
      <div class="row mt-5 ">
        <div class="col-sm-4">
          <div class="user-details">
            <h5>Email</h5>
          </div>
        </div>
        <div class="col-sm-8">
          <div>
            <p class="email">{{user.emailaddress}}</p>
          </div>
        </div>
      </div>

    </div>

    <div class="header py-4 border-bottom">
      <h4>Password</h4>
      <p>Change your password to a new one</p>
    </div>
    <div class="page-body my-5">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form>
          <div
            id="profileseth"
            class="row mt-5 "
          >
            <div class="col-sm-4">
              <div class="user-details">
                <h5>Enter Old Password</h5>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="form-group">
                <label
                  class="d-none d-sm-block"
                  for=""
                >Old Password</label>
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    :type=" passwordFieldType1"
                    class="form-control "
                    v-model="password_data.oldpassword"
                    placeholder="Enter your old password"
                  >
                  <span class="err_msg">{{ errors[0] }}</span>
                </validation-provider>
                <img
                  @click="switchVisibilityOld"
                  id="view-img"
                  src="../assets/img/view.png"
                  alt=""
                >
              </div>
            </div>
          </div>
          <div
            id="profileseth"
            class="row mt-5 "
          >
            <div class="col-sm-4">
              <div class="user-details">
                <h5>Enter New Password</h5>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="form-group">
                <label
                  class="d-none d-sm-block"
                  for=""
                >New Password</label>
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    :type=" passwordFieldType2"
                    v-model="password_data.newpassword"
                    class="form-control"
                    placeholder="Please enter a strong password"
                  >
                  <span class="err_msg">{{ errors[0] }}</span>
                </validation-provider>
                <img
                  @click="switchVisibilityNew"
                  id="view-img"
                  src="../assets/img/view.png"
                  alt=""
                >
              </div>
              <button
                @click.prevent='handleSubmit(updatePassword)'
                type="button"
                class="btn my-5"
              >Change Password</button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import * as $ from "jquery";
export default {
  name: 'Security',
  data () {
    return {
      user: {},
      passwordFieldType1: 'password',
      passwordFieldType2: 'password',
      password_data: {
        id: "",
        oldpassword: "",
        newpassword: ""
      }
    }
  },
  created () {
    let user = this.$store.getters.user;
    this.user = user;
    this.password_data.id = user.id;
  },
  mounted () {
    // console.log(this.$route.name)
  },
  methods: {
    updatePassword () {
      let req = {
        what: "password",
        showLoader: true,
        useToken: true,
        data: this.password_data
      }

      this.$request
        .makePostRequest(req)
        .then(res => {
          // console.log(res)
          this.$swal.fire("Success", res.data.message, "success");
          this.password_data.oldpassword = '';
          this.password_data.newpassword = '';
        })
        .catch(error => {
          console.log(error);
          this.$swal.fire("Error", error.message, "error");
        });
    },
    switchVisibilityOld () {
      this.passwordFieldType1 = this.passwordFieldType1 === 'password' ? 'text' : 'password'
    },
    switchVisibilityNew () {
      this.passwordFieldType2 = this.passwordFieldType2 === 'password' ? 'text' : 'password'
    }
  }

}
</script>