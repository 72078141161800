
<template>
  <div>
    <!-- dispatch receipt -->

    <div
      id="invoice-POS"
      v-if="$store.getters.businesscategory =='Delivery' && order !==null"
    >
      <center id="top">
        <div>
          <img
            :src="user.branchimage"
            alt=""
            style="height:50px "
            class="img-fluid mb-2"
          >
        </div>
        <div class="info">
          <h2>{{user.businessname}}</h2>
          <h5>{{user.branchname}}</h5>
        </div>
        <!--End Info-->
      </center>
      <!--End InvoiceTop-->
      <div id="mid">
        <div class="info my-2">
          <p> Ordercode : {{ordercode}}</p>
          <p>{{user.branchaddress}}<br />
            Phone : {{user.phonenumber}}<br />
            Cashier : {{user.firstname}} {{user.lastname}}</p>
        </div>
      </div>
      <!--End Invoice Mid-->
      <div id="bot my-2">
        <div class="row">
          <div class="col-6">
            <p>Item Name</p>
          </div>
          <div class="col-6">
            <p>{{order.itemname}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <p>Weight</p>
          </div>
          <div class="col-6">
            <p>{{order.weight}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <p>Item Quantity</p>
          </div>
          <div class="col-6">
            <p>{{order.itemquantity}}</p>
          </div>

        </div>
        <div class="row">
          <div class="col-6">
            <p>Pickup Address</p>
          </div>
          <div class="col-6">
            <p>{{order.pickupaddress}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <p>Delivery Address</p>
          </div>
          <div class="col-6">
            <p>{{order.deliveryaddress}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <p>ETA</p>
          </div>
          <div class="col-6">
            <p>{{order.ETA}}</p>
          </div>
        </div>
        <div
          id="table"
          class="mt-2"
        >
          <table>
            <tr class="">
              <td></td>
              <td class="Rate">
                <h2>Total</h2>
              </td>
              <td class="payment">
                <h2 class="text-right">₦{{formatPrice(order.ordertotal)}}</h2>
              </td>
            </tr>
            <tr
              class=""
              v-if="payment_method.includes('cash')"
            >
              <td></td>
              <td class="Rate">
              </td>
              <td class="payment">
                <p class="text-right"> ₦{{formatPrice(cash)}} on Cash</p>
              </td>
            </tr>
            <tr
              class=""
              v-if="payment_method.includes('pos')"
            >
              <td></td>
              <td class="Rate">
              </td>
              <td class="payment">
                <p class="text-right"> ₦{{formatPrice(pos)}} on Pos</p>
              </td>
            </tr>
            <tr
              class=""
              v-if="payment_method.includes('banktransfer')"
            >
              <td></td>
              <td class="Rate">
              </td>
              <td class="payment">
                <p
                  class="text-right"
                  style="font-size:12px;"
                > ₦{{formatPrice(transfer)}} on Bank Transfer</p>
              </td>
            </tr>
          </table>
        </div>
        <!--End Table-->
        <div id="legalcopy">

          <p class="text-center">Thank you for shopping with us. <br>
            {{formatDate(Date())}}<br /></p>
        </div>
      </div>
      <!--End InvoiceBot-->
    </div>
    <!-- other categories receipt -->
    <div
      v-else
      id="invoice-POS"
    >
      <center id="top">
        <div>
          <img
            :src="user.branchimage"
            alt=""
            style="height:50px "
            class="img-fluid mb-2"
          >
        </div>
        <div class="info">
          <h2>{{user.businessname}}</h2>
          <h5>{{user.branchname}}</h5>
        </div>
        <!--End Info-->
      </center>
      <!--End InvoiceTop-->
      <div id="mid">
        <div class="info my-2">
          <p>Ordercode : {{ordercode}}</p>
          <p> {{user.branchaddress}}<br />
            Phone : {{user.phonenumber}}<br />
          </p>
          <p>Cashier: {{user.firstname}} {{user.lastname}}</p>
        </div>
        <p>Qty : {{cart.length}}item(s)</p>
      </div>
      <!--End Invoice Mid-->
      <div id="bot my-2">
        <div id="table">
          <table>
            <tr
              class="service"
              v-for="(item, index) in cart"
              v-bind:key="index"
            >
              <td
                class="tableitem"
                style="width: 35px;"
              >
                <p class="itemtext">{{item.quantity}} x </p>
              </td>
              <td class="tableitem">
                <p
                  class="itemtext "
                  style="font-weight:900"
                >{{item.name}}</p>
              </td>
              <td class="tableitem">
                <p class="itemtext float-right">₦{{formatPrice(item.totalamount)}}</p>
              </td>
            </tr>
            <tr class="">
              <td></td>
              <td class="Rate">
                <h3>Subtotal</h3>
              </td>
              <td class="payment">
                <h3 class="text-right">₦{{formatPrice(subtotal)}}</h3>
              </td>
            </tr>
            <tr
              v-if="order.ordermethod=='delivery'"
              class=""
            >
              <td></td>
              <td class="Rate">
                <h3>Delivery Fee</h3>
              </td>
              <td class="payment">
                <h3 class="text-right">₦{{formatPrice(order.deliverycharge)}}</h3>
              </td>
            </tr>
            <tr class="">
              <td></td>
              <td class="Rate">
                <h3>Discount</h3>
              </td>
              <td class="payment">
                <h3 class="text-right">- ₦{{formatPrice(discount)}}</h3>
              </td>
            </tr>
            <tr class="">
              <td></td>
              <td class="Rate">
                <h2>Total</h2>
              </td>
              <td class="payment">
                <h2 class="text-right">₦{{formatPrice(total)}}</h2>
              </td>
            </tr>
            <tr
              class=""
              v-if="payment_method.includes('cash')"
            >
              <td></td>
              <td class="Rate">
              </td>
              <td class="payment">
                <p class="text-right"> ₦{{formatPrice(cash)}} on Cash</p>
              </td>
            </tr>
            <tr
              class=""
              v-if="payment_method.includes('pos')"
            >
              <td></td>
              <td class="Rate">
              </td>
              <td class="payment">
                <p class="text-right"> ₦{{formatPrice(pos)}} on Pos</p>
              </td>
            </tr>
            <tr
              class=""
              v-if="payment_method.includes('banktransfer')"
            >
              <td></td>
              <td class="Rate">
              </td>
              <td class="payment">
                <p
                  class="text-right"
                  style="font-size:12px;"
                > ₦{{formatPrice(transfer)}} on Bank Transfer</p>
              </td>
            </tr>
          </table>
        </div>
        <!--End Table-->
        <div id="legalcopy">
          <p class="text-center">Thank you for shopping with us. <br>
            {{formatDate(Date())}}</p>
        </div>
      </div>
      <!--End InvoiceBot-->
    </div>
  </div>

</template>

<script>
import "../assets/css/receipt.css"
export default {
  data () {
    return {
      user: null,
      order: null,
      cart: null,
      order_total: '',
      discount: '0',
      ordercode: "",
      printer_off: true,
      payment_method: []
    }
  },
  created () {
    this.user = this.$store.getters.user;
    let cart = this.$store.getters.cart;
    this.cart = cart;
    this.order = this.$store.getters.order;
    this.ordercode = this.$store.getters.ordercode;
  },
  mounted () {
    this.printer_off = false;
    for (let i of this.order.multiple) {
      this.payment_method.push(i.paymentmethod)
    }
  },
  watch: {
    printer_off (val) {
      let vm = this
      if (val == true) {

        // this.$store.dispatch('updateCart', []);
        // this.$router.go(-1);
      }
      else {
        setTimeout(() => {
          window.print();
        }, 3000)
        let isMobile = this.detectMob()
        if (isMobile) {
          if (window.matchMedia) {
            var mediaQueryList = window.matchMedia('print');
            mediaQueryList.addEventListener("change", (mql) => {
              if (mql.matches) {
                // console.log('before')
              } else {
                vm.$store.dispatch('updateCart', []);
              }
            });
          }
        }
        else {
          window.onafterprint = function (event) {
            vm.$store.dispatch('updateCart', []);
            vm.$router.go(-1)
            // if (vm.$store.getters.businesscategory !== 'Delivery') {
            //   vm.$router.push('/walk-in')
            // }
            // else {
            //   vm.$router.push('/dispatch')
            // }

          };

        }

      }
    }
  },
  computed: {
    subtotal () {
      let sum = 0;
      let vm = this;
      this.cart.forEach(i => {
        sum += Number(i.totalamount);

      });
      this.order_total = sum;
      return sum;
    },

    total () {
      let sum = 0;
      let vm = this;
      this.cart.forEach(i => {
        sum += Number(i.totalamount);

      });
      this.order_total = sum;
      sum = this.order.ordermethod == 'delivery' ? Number(this.order_total) + Number(this.order.deliverycharge) - Number(this.discount) : Number(this.order_total) - Number(this.discount);
      return sum;
    },
    cash () {
      for (let i of this.order.multiple) {
        if (i.paymentmethod == 'cash') return i.paymentamount;
      }
    },
    pos () {
      for (let i of this.order.multiple) {
        if (i.paymentmethod == 'pos') return i.paymentamount;
      }
    },
    transfer () {
      for (let i of this.order.multiple) {
        if (i.paymentmethod == 'banktransfer') return i.paymentamount;
      }
    }
  },
  methods: {
    detectMob () {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ];

      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });
    },
    formatPrice (price) {
      var str = price.toString().split(".");
      if (str[0].length >= 3) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      }
      if (!str[1]) {
        str[1] = "00";
      }
      return str.join(".");
    },
    formatDate (date, format) {
      if (date !== null) {
        let d = new Date(date)
        if (format == 'time') {

          return d.toLocaleTimeString('en-US');
        }
        else if (format == 'date') {
          return d.toDateString().replace(' ', ', ');
        }
        else {
          return d.toDateString().replace(' ', ', ') + ' ' + d.toLocaleTimeString('en-US');
        }
      }
      else {
        return null
      }
    },


  }
};
</script>
<style scoped>
</style>

