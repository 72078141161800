// import { update, index, store, destroy } from '@/api/resource'#
import Request from '@/Request.js'
import db from '../../DB';

export default {
    namespaced: true,
    state: () => ({
        staffs: [],
    }),
    mutations: {
        setUsers: (state, payload) => {
            state.users = payload;
            db.staffs.bulkPut(payload)
        },

    },
    actions: {
        getUsers({ commit, state }, req) {
            return new Promise((resolve, reject) => {
                Request.makeGetRequest(req)
                    .then(response => {
                        const { data } = response
                        if (data.status) {
                            if (data.data != undefined) {
                                commit('setUsers', data.data)
                            }
                        } else {
                            vue.$swal.fire("Error", res.data.message, "error");
                        }
                        resolve(db.staffs.toArray())
                    }).catch(error => {

                        reject(error)
                    })
            })
        },

    },
    getters: {
        getUser: (state) => (params) => {
            let res = db.staffs.where('[lastname+usertoken]').equalsIgnoreCase(params.firstname.toLowerCase().trim(), params.token).first();

            return res
        },
    }
}