<template>
  <div id="profile">
    <Sidemenu />
    <div id="main">
      <Topmenu />
      <div class="main-content">
        <div class="container mt-5 px-2">
          <div id="profile-body">
            <div class="links-wrap tab_container pt-4 px-5 border-bottom">
              <ul
                class="nav"
                id="myTab"
                role="tablist"
              >
                <li class="">
                  <a
                    class="active"
                    id="account-tab"
                    data-toggle="tab"
                    href="#account"
                    role="tab"
                    aria-controls="account"
                    aria-selected="true"
                  >Account</a>
                </li>
                <!-- <li class="ml-5">
                  <a
                    class=""
                    id="security-tab"
                    data-toggle="tab"
                    href="#security"
                    role="tab"
                    aria-controls="security"
                    aria-selected="false"
                  >Security</a>
                </li> -->

              </ul>
            </div>

            <div class="container mt-5">
              <div>
                <div
                  class="tab-content"
                  id="myTabContent"
                >
                  <div
                    class="tab-pane fade show active"
                    id="account"
                    role="tabpanel"
                    aria-labelledby="account-tab"
                  >
                    <Account />
                  </div>
                  <div
                    class="tab-pane fade"
                    id="security"
                    role="tabpanel"
                    aria-labelledby="security-tab"
                  >
                    <Security />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidemenu from '@/components/sidemenu'
import Topmenu from '@/components/topmenu'
import Account from '@/components/account'
import Security from '@/components/security'
export default {
  name: 'Profile',
  components: {
    Sidemenu, Topmenu, Account, Security
  },
  data () {
    return {
      user: {}
    }
  },
  created () {
    this.user = this.$store.getters.user;
  },
  methods: {
  }
}
</script>
