<template >
  <div id="aside">
    <div
      class="text-center mt-3"
      @click="$router.push('/orders')"
    >
      <img
        class="side-img img-fluid"
        :src=companyLogo
        alt=""
      >

    </div>

    <div
      v-if="$store.getters.businesscategory ==='Food' && $store.getters.user.role ==='kitchen'"
      id="opt-box"
      class=" text-center"
    >

      <router-link
        tag="div"
        class="opt mt-4"
        v-bind:class="$route.name=='Kitchen'? 'active': ''"
        v-bind:to="'/kitchen'"
      >
        <img
          v-if="$route.name=='Kitchen'"
          src="../assets/img/queue-icon-active.png"
          alt=""
        >
        <img
          v-else
          src="../assets/img/queue-icon.png"
          alt=""
        >
        <p>Kitchen </p>
      </router-link>

    </div>
    <div
      v-else
      id="opt-box"
      class=" text-center"
    >
      <router-link
        class="opt mt-4"
        tag="div"
        v-bind:class="$route.name=='Online Orders'||$route.name=='OnlineOrder'? 'active': ''"
        v-bind:to="'/orders'"
      >
        <img
          v-if="$route.name=='Online Orders' ||$route.name=='OnlineOrder'"
          src="../assets/img/orders-icon-active.png"
          alt=""
        >
        <img
          v-else
          src="../assets/img/orders-icon.png"
          alt=""
        >

        <p>Online Orders</p>
      </router-link>
      <router-link
        v-if="$store.getters.businesscategory !=='Delivery'"
        tag="div"
        class="opt mt-4"
        v-bind:class="$route.name=='Walk-in'? 'active': ''"
        v-bind:to="'/walk-in'"
      >
        <img
          v-if="$route.name=='Walk-in'"
          src="../assets/img/walkin-icon-active.png"
          alt=""
        >
        <img
          v-else
          src="../assets/img/walkin-icon.png"
          alt=""
        >
        <p>Walk in</p>
      </router-link>
      <router-link
        v-else
        tag="div"
        class="opt mt-4"
        v-bind:class="$route.name=='Dispatch'? 'active': ''"
        v-bind:to="'/dispatch'"
      >
        <img
          v-if="$route.name=='Dispatch'"
          src="../assets/img/walkin-icon-active.png"
          alt=""
        >
        <img
          v-else
          src="../assets/img/walkin-icon.png"
          alt=""
        >
        <p>Walk in</p>
      </router-link>
      <!-- <router-link
        v-if="$store.getters.businesscategory ==='Food'"
        tag="div"
        class="opt mt-4"
        v-bind:class="$route.name=='Orders In Queue'? 'active': ''"
        v-bind:to="'/queue'"
      >
        <img
          v-if="$route.name=='Orders In Queue'"
          src="../assets/img/queue-icon-active.png"
          alt=""
        >
        <img
          v-else
          src="../assets/img/queue-icon.png"
          alt=""
        >
        <p>Queued Orders</p>
      </router-link> -->

      <router-link
        v-if="$store.getters.businesscategory ==='Food'"
        tag="div"
        class="opt mt-4"
        v-bind:class="$route.name=='Kitchen'? 'active': ''"
        v-bind:to="'/kitchen'"
      >
        <img
          v-if="$route.name=='Kitchen'"
          src="../assets/img/kitchen-icon-active.png"
          alt=""
        >
        <img
          v-else
          src="../assets/img/kitchen-icon.png"
          alt=""
        >
        <p>Kitchen </p>
      </router-link>
      <router-link
        v-if="$store.getters.businesscategory !=='Delivery'"
        tag="div"
        class="opt mt-4"
        v-bind:class="$route.name=='Products'? 'active': ''"
        v-bind:to="'/products'"
      >
        <img
          v-if="$route.name=='Products'"
          src="../assets/img/product-icon-active.png"
          alt=""
        >
        <img
          v-else
          src="../assets/img/product-icon.png"
          alt=""
        >
        <p>Products</p>
      </router-link>
      <router-link
        tag="div"
        class="opt mt-4"
        v-bind:class="$route.name=='History'? 'active': ''"
        v-bind:to="'/order-history'"
      >
        <img
          v-if="$route.name=='History'"
          src="../assets/img/history-icon-active.png"
          alt=""
        >
        <img
          v-else
          src="../assets/img/history-icon.png"
          alt=""
        >
        <p>Order History</p>
      </router-link>
      <router-link
        tag="div"
        class="opt mt-4"
        v-bind:class="$route.name=='Summary'? 'active': ''"
        v-bind:to="'/summary'"
      >
        <img
          v-if="$route.name=='Summary'"
          src="../assets/img/summary-icon-active.png"
          alt=""
        >
        <img
          v-else
          src="../assets/img/summary-icon.png"
          alt=""
        >
        <p>Sales Summary</p>
      </router-link>
    </div>

  </div>
</template>
<script>
import { eventBus } from "../main";
import * as $ from "jquery";
export default {
  name: 'Sidenav',
  data () {
    return {
      companyLogo: ''
    }
  },
  created () {
    this.companyLogo = this.$store.getters.user.branchimage;
    // console.log(this.$store.getters.user)
  },
  // mounted () {
  //   eventBus.$on('fireMethod', () => {
  //     $('#aside').css("transform", function () {
  //       return $('input#openSidebarMenu')[0].checked ? "translateX(0)" : "translateX(-100px)";
  //     });
  //     if (window.innerWidth <= 320) {
  //       $('#main').css("padding-left", function () {
  //         return $('input#openSidebarMenu')[0].checked ? "60px" : "0px";
  //       });
  //     }
  //     else if (window.innerWidth <= 425) {
  //       $('#main').css("padding-left", function () {
  //         return $('input#openSidebarMenu')[0].checked ? "80px" : "0px";
  //       });
  //     }
  //     else {
  //       $('#main').css("padding-left", function () {
  //         return $('input#openSidebarMenu')[0].checked ? "100px" : "0px";
  //       });
  //     }
  //   })
  // },


}
</script>