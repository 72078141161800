<template >
  <section>
    <div id="category-menu">
      <ul class=" mx-sm-3 nav">
        <li
          v-for="(cat, index_i) in categories"
          v-bind:key="index_i"
          class=" nav-item"
          v-bind:class="cat.active? 'active':''"
          @click="viewProducts(cat,index_i)"
        >{{cat.name}}</li>
      </ul>
    </div>
  </section>
</template>
<script>
import * as $ from "jquery";
export default {
  name: 'Menu',
  props: {
    categories: Array
  },
  mounted () {
    // console.log(this.categories)
  },
  methods: {
    viewProducts (cat, index) {
      this.categories.forEach(i => {
        if (i.name !== cat.name) {
          i.active = false;
        }
        else {
          i.active = true;
        }
      });
      this.$emit('childToParent', cat, index)
    }
  }

}
</script>