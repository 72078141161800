<template>
  <div>
    <div
      v-if="this.$router.currentRoute.name === 'Login'"
      id="login-app"
    >
      <router-view />
    </div>
    <div
      v-else-if="this.$router.currentRoute.name === 'Receipt'"
      id="receiptapp"
    >
      <router-view />
    </div>
    <div
      v-else
      id="app"
    >
      <router-view />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
  //  const { mapActions, mapGetters } = createNamespacedHelpers('posts')
// import Sync from  './sync.js'
import Utils from  '@/utils.js'

import sync from '@/workers/Sync'

  
export default {
  data () {
    return {
      status: '',
      type: '',
      icontype: ''
    }
  },
  created () {

    sync.send({checkNetwork: true })

    let vm = this;
    let loading_html =
                    '<div style="height:150px;width:150px;margin: 0 auto;"><img style="width: 100%;" src="https://trails.ca/wp-content/uploads/2020/10/loading-spinner.gif" /></div>';

    sync.worker.onmessage = event => {
      let message = event.data

      if (message.key == true ) {
        if(message.value == "Sync Posts"){
          this.syncRequests()
        }

        if(message.value == 'commit'){
          this.$store.commit(message.commit.type, message.commit.payload)
        }

        if(message.value == 'load'){
          console.log('loading')
          // vm.$wal.showLoading()
          this.$store.commit('load', true)
          vm.$swal.fire({
                    title: "Downloading Data, Please do not disconnect",
                    html: loading_html,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showCancelButton: false
                });
        }

        if(message.value == 'unload4'){
          vm.$swal.close()
          this.$store.commit('load', false)
          this.$store.commit('first', false)
          vm.$swal.fire("Success", "Download Completed Successfully", "success");
        }

        if(message.value == 'error'){
          this.$store.commit('load', false)
          vm.$swal.fire("Download Failed", message.error, "error");
        }
      }
    }

    window.addEventListener('online', () => {
      this.status = 'App is Online';
      this.type = 'success';
      this.icontype = 'check'
      });

    window.addEventListener('offline', () => {
      this.status = 'App is Offline';
      this.type = 'error';
      this.icontype = 'exclamation-triangle'
    });
  },
  watch: {
    status (val) {
      this.$toasted.show(val, {
        theme: "bubble",
        type: this.type,
        position: "top-right",
        duration: 6000,
        icon: {
          name: this.icontype,
          after: true
        }
      });
    }
  },

  methods : {
     ...mapActions({
      'makePosts' : 'posts/makePosts',
      'loginSync' : 'loginSync',
    }),

    async syncRequests () {
      let vm = this;
      let shouldFetch = !(await vm.$store.dispatch('posts/shouldFetch'));

      // console.log(shouldFetch)
      if(!vm.$store.state.posts.active && vm.$store.getters.isLoggedIn && shouldFetch) {
        vm.$store.commit('posts/SET_STATUS', true)
        var loading_html =
                    '<div style="height:150px;width:150px;margin: 0 auto;"><img style="width: 100%;" src="https://trails.ca/wp-content/uploads/2020/10/loading-spinner.gif" /></div>';

                
        
        this.loginSync().then(() => {
          vm.$swal.fire({
                    title: "Sync Has Started, Hold On till Complete",
                    html: loading_html,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showCancelButton: false
                });
          this.makePosts().then((result) => {
            vm.$store.commit('posts/SET_STATUS', false)
            vm.$swal.close()
            vm.$swal.fire("Success", "Sync Completed Successfully", "success");
          }).catch((error) => {
            vm.$store.commit('posts/SET_STATUS', false)
            vm.$swal.close()

            vm.$swal.fire("Sync Failed", error, "error");
            // this.status = 'Sync Failed with error: ' + error;
            // this.type = 'error';
            // this.icontype = 'exclamation-triangle'
          });
        }).catch((error) => {
            vm.$store.commit('posts/SET_STATUS', false)
            vm.$swal.close()

            vm.$swal.fire("Sync Failed", error, "error");
        });
      }
    }
  }

}
</script>
<style>
#app {
  background: #f1f1f1 0% 0% no-repeat padding-box;
  min-height: 100vh;
  padding-bottom: 20px;
}
#login-app {
  background: #ffffff;
  padding-bottom: 0px;
}
</style>
