<template >
  <div>
    <div id="signin">
      <div class="row p-sm-3">
        <div class="col-sm-7 ">
          <div class="logo mb-5 mt-3 ml-3">
            <img
              src="../assets/img/logo-hoz.png"
              alt=""
            >
          </div>

          <div class="auth-form pt-5 mx-auto">
            <div class="heading d-flex justify-content-start ">
              <img
                src="../assets/img/reset.svg"
                alt=""
              >
              <div class="ml-3">
                <h3> Create new password</h3>
                <p>Enter a new password for your account</p>
              </div>
            </div>
            <div>
              <ValidationObserver v-slot="{ handleSubmit }">
                <form
                  class="mt-4"
                  @submit.prevent='handleSubmit(handleReset)'
                >
                  <div class="form-group">
                    <label for="">New Password</label>
                    <validation-provider
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <input
                        name="password"
                        type="password"
                        class="form-control"
                        placeholder="Please enter a strong password"
                        v-model="username"
                      >
                      <span class="extra">Enter username used to create your Ntisa account</span>
                      <span class="err_msg">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>

                  <div class="form-group">
                    <label for="">Re-enter New Password</label>
                    <validation-provider
                      name="confirm"
                      rules="required|confirmedBy:@password"
                      v-slot="{ errors }"
                    >
                      <input
                        type="password"
                        class="form-control"
                        placeholder="Enter the new password again"
                        v-model="password"
                      >
                      <span class="err_msg">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group">
                    <button
                      type="submit"
                      class="btn"
                    >
                      Confirm Password Change
                    </button>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
        <div class="col-sm-5 d-none d-sm-block">
          <div class="auth-details px-3">
            <div class="mr-3 title  text-right">
              <img
                src="../assets/img/logo-mini.png"
                alt=""
              >
              <h4>Don't Worry <br>
                It happens to the best of us</h4>
            </div>
            <div class="login-bg">
              <img
                src="../assets/img/forgot-bg.svg"
                alt=""
              >
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: "ResetPassword",
  data () {
    return {
      username: '',
      password: '',
      role: 'store_attendant'
    }
  },
  methods: {
    handleReset () {
      let req = {
        what: "login",
        params: {
          username: this.username,
          password: this.password,
          role: this.role
        }
      }
      this.$request.makeGetRequest(req)
        .then(res => {
          if (res.type == 'login') {
            this.$swal.close()

          }

        })
        .catch(error => {
          this.$swal.fire("Error", error.message, "error");
          console.log(error)
        });
    },
  }
}
</script>
<style scoped>
#signin {
  background: #ffffff;
}
.back a {
  color: var(--light-gray);
  text-align: left;

  letter-spacing: 0px;
}
.back a p {
  font: normal normal 600 17px/22px Source Sans Pro;
}
</style>