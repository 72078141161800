import Vue from 'vue'
import VueRouter from 'vue-router';
import Orders from '../views/Orders.vue'
import Login from '../views/Login.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import WalkIn from '../views/Walk-in.vue'
import OnlineOrder from '../views/OnlineOrder.vue'
import Dispatch from '../views/Dispatch.vue'
import Products from '../views/Products.vue'
import OrderHistory from '../views/OrderHistory.vue'
import Queue from '../views/Queue.vue'
import KitchenQueue from '../views/KitchenQueue.vue'
import OrderProcess from '../views/OrderProcess.vue'
import Profile from '../views/Profile.vue'
import Receipt from '../views/Receipt.vue'
import KitchenOrder from '../views/KitchenOrder.vue'
import Summary from '../views/Summary.vue'
import PrintSummary from '../views/PrintSummary.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Login',
        component: Login,
        meta: {
            public: true, // Allow access to even if not logged in
            onlyWhenLoggedOut: true
        }
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {
            public: true, // Allow access to even if not logged in

        }
    },
    {
        path: '/reset',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
            public: true, // Allow access to even if not logged in

        }
    },
    {
        path: '/orders',
        name: 'Online Orders',
        component: Orders,
        meta: {
            notkitchen: true
        }
    },
    {
        path: '/receipt',
        name: 'Receipt',
        component: Receipt,
        meta: {
            redirect_link: true, // Allow access only on redirect
            notkitchen: true
        }
    },
    {
        path: '/kitchen-order',
        name: 'KitchenOrder',
        component: KitchenOrder,
        meta: {
            redirect_link: true, // Allow access only on redirect
        }
    },
    {
        path: '/summary',
        name: 'Summary',
        component: Summary,
        meta: {
            notkitchen: true
        }
    }, {
        path: '/print-summary',
        name: 'PrintSummary',
        component: PrintSummary,
        meta: {
            redirect_link: true, // Allow access only on redirect
            notkitchen: true
        }
    },
    {
        path: '/walk-in',
        name: 'Walk-in',
        component: WalkIn,
        meta: {
            notkitchen: true
        }
    },
    //credit order
    {
        path: '/online-order',
        name: 'OnlineOrder',
        component: OnlineOrder,
        meta: {
            notkitchen: true
        }
    },
    //for dispatch category
    {
        path: '/dispatch',
        name: 'Dispatch',
        component: Dispatch,
        meta: {
            notkitchen: true
        }
    },
    // {
    //     path: '/order-process',
    //     name: 'Checkout',
    //     component: OrderProcess
    // },
    {
        path: '/products',
        name: 'Products',
        component: Products,
        meta: {
            notkitchen: true
        }
    },
    {
        path: '/order-history',
        name: 'History',
        component: OrderHistory,
        meta: {
            notkitchen: true
        }
    },
    {
        path: '/queue',
        name: 'Orders In Queue',
        component: Queue,
        meta: {
            notkitchen: true
        }
    },
    {
        path: '/kitchen',
        name: 'Kitchen',
        component: KitchenQueue
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile
    },


]

const router = new VueRouter({
    // mode: process.env.IS_ELECTRON ? 'hash' : 'history',
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router