import store from "./store";
import Request from "./Request"
export default {
    name: "Utils",

    async hashCode(message) {
        const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
        const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
        return hashHex;
      },

    checkShouldStressSync(request_store) {
        if(['createcustomer', 'addToQueue', 'order', 'mergequeue'].includes(request_store.what) && request_store.data != undefined){
            return request_store.what;
        }
        return false;
    },

    async setCustomer(order) {
        let customer = null;
        if(order.customerID != null && order.customerID != ""){
            
            customer = await store.dispatch('customers/getcustomer', order.customerID);
            console.log(customer)
            order.firstname = customer.firstname
            order.lastname = customer.lastname
            order.phonenumber = customer.phonenumber
        }

        return order
    },

    getDate() {
      let date_ob = new Date();

      // adjust 0 before single digit date
      let date = ("0" + date_ob.getDate()).slice(-2);

      // current month
      let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

      // current year
      let year = date_ob.getFullYear();

      // current hours
      let hours = date_ob.getHours();

      // current minutes
      let minutes = date_ob.getMinutes();

      // current seconds
      let seconds = date_ob.getSeconds();

      // prints date & time in YYYY-MM-DD HH:MM:SS format
      return year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
    },

    setOrderItems(order) {
        if(order.orders != undefined){
            order.orderitems = order.orders
            console.log(order.orderitems)

            let res = order.orderitems.map(e => {
                if(e.price != undefined){
                  e.unitprice = e.price
                  e.productID = e.productid
                  e.totalprice = e.totalamount
                //   e.name = e.productname
                if(e.name != undefined) {
                  e.itemname = e.name
                }
                if(e.itemname != undefined) {
                  e.name = e.itemname
                }
                //   e.image = e.photo
                }
                return e
            });

            order.orderitems = res

            console.log(order.orderitems)

            
          }
          
        //   console.log(order);
          return order;
    },

    async updateProductQuantity (order) {
        console.log(order)
        for (const item of order.orderitems) {
            await store.dispatch('products/reduceQuantity', item);
        }
    },

    isPutRequest(req) {
        return ['editProduct'].includes(req.what)
    },

    isDeleteRequest(req) {
        return ['addresses'].includes(req.what)
    },

    async checkOnlineStatus () {
        // return navigator.onLine ;
        try {
          const online = await fetch('https://wajeloyalty.website/API/networkstatus/');
          return online.status >= 200 && online.status < 300; // either true or false
        } catch (err) {
            console.log('innnetwork error')

          return false; // definitely offline
        }
    },

    shouldGo (what) {
            // emailreceipt: 'emailreceipt/'
            return ['password', 
            'deliveryorder', 'assignrider', 'calculatedelivery', 
            'cancelorder', 'processpickup', 'getRiders', 'getRewards', 'emailreceipt', 'redeem'].includes(what);
            // return this.checkOnlineStatus && 
    }
}