// import { update, index, store, destroy } from '@/api/resource'#
import Request from '@/Request.js'
import db from '@/DB.js'

export default {
    namespaced: true,
    state: () => ({
        products: [],
    }),
    mutations: {
        SET_PRODUCTS: async(state, products) => {
            // state.products = products
            // products.map((category) => category.header.data).reduce((acc, curr) => { acc.push(...curr); return acc }, []).forEach((product) => {
            //   console.log(product)
            //   db.products.put(product)

            // })
            // products.map((category) => category.header.data).reduce((acc, curr) => { acc.push(...curr); return acc }, [])
            let products_all = products.map((category) => {
                // category.header.data.category = category.category
                return category.header.data.map((product) => {
                    product.category = category.category;
                    return product
                })
            }).reduce((acc, curr) => { acc.push(...curr); return acc }, [])



            // db.products.clear();
            await db.products.bulkPut(products_all)

            // db.categories.clear();
            await db.categories.bulkPut(products.map((category) => {
                category.header.data = null
                return category
            }));

            // console.log(state.products)
        },

        SET_PRODUCTS_ASYNC: async(state, products) => {
            // state.products = products
            // products.map((category) => category.header.data).reduce((acc, curr) => { acc.push(...curr); return acc }, []).forEach((product) => {
            //   console.log(product)
            //   db.products.put(product)

            // })
            // products.map((category) => category.header.data).reduce((acc, curr) => { acc.push(...curr); return acc }, [])
            let products_all = products.map((category) => {
                // category.header.data.category = category.category
                return category.header.data.map((product) => {
                    product.category = category.category;
                    return product
                })
            }).reduce((acc, curr) => { acc.push(...curr); return acc }, [])



            db.products.clear();
            await db.products.bulkPut(products_all)

            db.categories.clear();
            await db.categories.bulkPut(products.map((category) => {
                category.header.data = null
                return category
            }));

            // console.log(state.products)
        },

        UPDATE_PRODUCT: async(state, product_data) => {
            await db.products.put(product_data)
                // await db.products.where('header.data.id').equals(product_data.id).modify(product_data)
                // await db.products.where('header.data.id').equals(product_data.id).modify(product_data)

            // if( state.products.length !== 0 ) {
            //   Object.assign(state.products.find((category) => category.header.data.find((product) => product.id == product_data.id) ) , product_data)
            // } else {
            //   state.products.push(product_data)
            // }
        },
        ADD_PRODUCT: (state, product_data) => {
            // product.id = state.products.length + 1
            // state.products.find((category) => category.

            db.products.put(product_data)
                // state.products.push(product_data)
        },

    },
    actions: {

        index({ commit, state }, req) {
            return new Promise(async(resolve, reject) => {
                if (req.params.search == '') {
                    Request.makeGetRequest(req)
                        .then(async response => {
                            const { data } = response
                            if (data.status) {
                                if (data.data != undefined) {

                                    commit('SET_PRODUCTS', data.data)
                                }
                            }

                            let available = req.params.available != undefined && req.params.available == true
                            let categories = [];
                            if (req.params.categoryID != undefined) {
                                categories = await db.categories.where('header.category').equals(req.params.categoryID).toArray()
                            } else {
                                categories = await db.categories.toArray()
                            }

                            let limit = 10;
                            let offset = 0;
                            if (req.params.page == 1) {
                                offset = 0;
                            } else {
                                offset = ((req.params.page - 1) * limit);
                            }

                            let result = await Promise.all(categories.map(async(category) => {
                                if (available) {
                                    // console.log('is available')
                                    category.header.data = (await db.products.where('category').equals(category.category).and((pro) => pro.outofstock !== true).offset(offset).limit(limit).toArray())
                                    category.header.Count = await db.products.where('category').equals(category.category).and((pro) => pro.outofstock !== true).count()
                                } else {
                                    category.header.data = (await db.products.where('category').equals(category.category).offset(offset).limit(limit).toArray())
                                }
                                return category
                            }));
                            resolve(result.filter((category) => category.header.data.length != 0))
                        }).catch(error => {
                            console.log(error);
                            reject(error)
                        })
                } else {
                    let categories = [];
                    if (req.params.categoryID != undefined) {
                        categories = await db.categories.where('header.category').equals(req.params.categoryID).toArray()
                    } else {
                        categories = await db.categories.toArray()
                    }

                    let limit = 10;
                    let offset = 0;
                    if (req.params.page == 1) {
                        offset = 0;
                    } else {
                        offset = ((req.params.page - 1) * limit);
                    }

                    let name = req.params.search;
                    let result = [];
                    let available = req.params.available != undefined && req.params.available == true
                    if (name == '') {
                        result = await Promise.all(categories.map(async(category) => {

                            if (available) {

                                category.header.data = (await db.products.where('category').equals(category.category).and((pro) => pro.outofstock != true).offset(offset).limit(limit).toArray())

                            } else {
                                category.header.data = (await db.products.where('category').equals(category.category).offset(offset).limit(limit).toArray())
                            }
                            return category
                        }))

                    } else {
                        let search = name;
                        result = await Promise.all(categories.map(async(category) => {
                            if (available) {
                                category.header.data = (await db.products.where('category').equals(category.category).and((pro) => {

                                    if (pro.productname.toLowerCase().includes(search.toLowerCase())) {
                                        return pro.productname.toLowerCase().includes(search.toLowerCase());
                                    }

                                    if (pro.productcode !== null && pro.productcode.includes(search.toLowerCase())) {
                                        return pro.productcode && pro.productcode.includes(search.toLowerCase());
                                    }

                                }).offset(offset).limit(limit).toArray())
                            } else {
                                category.header.data = (await db.products.where('category').equals(category.category).and((pro) => {

                                    if (pro.productname.toLowerCase().includes(search.toLowerCase())) {
                                        return pro.productname.toLowerCase().includes(search.toLowerCase()) && pro.outofstock != true
                                    }

                                    if (pro.productcode !== null && pro.productcode.includes(search.toLowerCase())) {
                                        return pro.productcode && pro.productcode.includes(search.toLowerCase()) && pro.outofstock != true
                                    }

                                }).offset(offset).limit(limit).toArray())
                            }
                            return category
                        }));

                    }
                    resolve(result.filter((category) => category.header.data.length != 0))
                }
            })
        },

        async getProduct({ commit }, id) {
            return await db.products.where('id').equals(id).first()
        },

        updateStock({ commit, getters, dispatch }, req) {

            return new Promise((resolve, reject) => {

                Request.makePostRequest(req)
                    .then(async response => {

                        // let product = await getters.getProduct(req.data.productID); 
                        let product = await dispatch('getProduct', req.data.productID);
                        if (product != null) {
                            product.quantity = Number(product.quantity) + Number(req.data.inwardqty)
                            commit('UPDATE_PRODUCT', product)
                        }

                        resolve(response)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },

        updateStockStatus({ commit, getters, dispatch }, req) {

            return new Promise((resolve, reject) => {

                Request.makePostRequest(req)
                    .then(async response => {

                        // let product = getters.getProduct(req.data.id);

                        // console.log('error')
                        // console.log(req.data)
                        let product = await dispatch('getProduct', req.data.id);
                        if (product != null) {
                            product.outofstock = req.data.outofstock == 1 ? true : false;
                            commit('UPDATE_PRODUCT', product)
                        }

                        resolve(response)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },

        reduceQuantity({ commit, getters, dispatch }, data) {

            return new Promise(async(resolve, reject) => {

                try {
                    let product = await dispatch('getProduct', data.productID);
                    if (product != null) {

                        // let product = getters.getProduct(data.productID);

                        product.stockqty = Number(product.stockqty) - Number(data.quantity);


                        commit('UPDATE_PRODUCT', product)
                    }

                    resolve(product)
                } catch (error) {
                    reject(error)
                }
            })
        },

        async getProductThroughBarcode({ commit }, productcode) {
            return await db.products.get({ productcode: productcode })
        },

        store({ commit }, product, req) {
            return new Promise((resolve, reject) => {
                this.$request.makePostRequest(req)
                    .then(response => {
                        const { data } = response
                        commit('ADD_PRODUCT', product)
                        resolve()
                    }).catch(error => {
                        reject(error)
                    })
            })
        },

        update({ commit }, product, req) {
            return new Promise((resolve, reject) => {
                this.$request.makePostRequest(req).then(response => {
                    const { data } = response
                    commit('UPDATE_PRODUCT', data.data)
                    resolve(data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    },
    getters: {
        products: (state) => (type) => state.products.filter((product) => type != '' ? product.productstatus == type : true),
        getAllProducts: (state) => state.products.map((category) => category.header.data).reduce((acc, curr) => { acc.push(...curr); return acc }, []),
        getProduct: (state) => (id) => state.products.find((category) => category.header.data.find((product) => product.id == id)).header.data.find((product) => product.id == id),
        getProductThroughBarcode: (state) => (barcode) => {
            // console.log(barcode)
            let result = state.products.find((category) => category.header.data.find((product) => product.productcode == barcode));
            if (result != null) {
                return result.header.data.find((product) => product.productcode == barcode)
            } else {
                return null;
            }
        },
    }
}